import { createContext, useContext } from 'react'
import { useMachine } from '@xstate/react'
import { MainMachine } from 'machine/index'
import { isDevMode } from 'constants/base'
import ReactGA from 'react-ga'

const MainContext = createContext()

export const useMainContext = () => useContext(MainContext)

const MainProvider = ({ children }) => {
  const [state, send] = useMachine(MainMachine, { devTools: isDevMode, context: { ReactGA } })

  // For Debug
  if (isDevMode) {
    console.log('🚀Machine state')
    console.log(state)
    console.log(state.context)
  }

  const getSendActions = () => ({
    goToOverviewPage: () => send('GO_TO_OVERVIEW_PAGE'),
    goToMapPage: () => send('GO_TO_NEW_MAP_PAGE'),
    goToPanoPage: () => send('GO_TO_PANO_PAGE'),
  })

  const getMainContext = () => {
    const { context } = state
    return {
      state,
      send,
      context,
      actions: {
        ...getSendActions(),
      },
    }
  }

  return <MainContext.Provider value={getMainContext()}>{children}</MainContext.Provider>
}

export default MainProvider
