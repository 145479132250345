/* eslint-disable import/no-anonymous-default-export */
import { assign } from 'xstate'
import { getPageState } from 'utils/xstate'
import { getSearchParamString, getMapPath } from 'utils/base'
import history from 'config/history'
import { PATH, FLOOR_PATH } from 'constants/path'
import { PAGE_STATUS } from 'constants/xstate'
import {
  getLocalStorage,
  setLocalStorage,
  checkIsLocalStorageExpired,
  parseSearchParams,
} from 'utils/base'
import { isArray } from 'lodash'
import { getCurrentDate } from 'utils/base'
import { getVisitedArrowImg } from 'utils/krpano'
import { LOCAL_STORAGE_KEY, PANO_SPOT_TYPE } from 'constants/base'
import { GA_ID } from 'config/base'
import { SCENE_WITH_AUTOPLAY_VIDEO, KRPANO_ACTION } from 'config/krpano'
import { isDevMode } from 'constants/base'

const { TOUR, PHOTO } = PANO_SPOT_TYPE

const routeActions = {
  handlePageEntry: (
    { currentFloor, ReactGA },
    { hash, searchParam = {}, extraPath, keepHash = true, keepSearchParams = true },
    { state: { value } }
  ) => {
    const { search, hash: originHashValue } = history.location
    let newPath = PATH[getPageState(value)]

    // Check and add additional url path
    if (extraPath) {
      newPath += extraPath
    } else if (getPageState(value) === PAGE_STATUS.MAP) {
      const mapPath = getMapPath()
      mapPath ? (newPath += mapPath) : (newPath += currentFloor)
    }

    // Check and add search params
    if (keepSearchParams) {
      const searchParamStr = getSearchParamString({ ...parseSearchParams(search), ...searchParam })
      newPath += searchParamStr
    }

    // Check and add hash value
    if (keepHash) {
      hash ? (newPath += `#${hash}`) : (newPath += originHashValue)
    }

    ReactGA.pageview(newPath)
    history.push(newPath)
  },
  changeMapUrl: ({ ReactGA }, { currentFloor = FLOOR_PATH.F1, qrcodeSearchParamStr }) => {
    const { search } = history.location
    const newSearch = qrcodeSearchParamStr ? qrcodeSearchParamStr : search

    const newPath = `${PATH[PAGE_STATUS.MAP]}${currentFloor}${newSearch}`
    ReactGA.pageview(newPath)
    history.push(newPath)
  },
  changePanoSceneUrl: ({ ReactGA }, { scene }) => {
    const { search, pathname } = history.location

    const newPath = `${pathname}${search}#${scene}`
    ReactGA.pageview(newPath)
    history.push(newPath)
  },
  setSelectedSceneFromUrl: assign({
    urlScene: ({ currentFloor }) => {
      const params = new URLSearchParams(history.location.search)
      const sceneParam = params.get('scene')
      if (sceneParam) {
        return sceneParam
      }
      return null
    },
  }),
}

const setActions = {
  setQrcodeScene: assign({ qrcodeScene: (_, { qrcodeScene }) => qrcodeScene }),
  cleanQrcodeResult: assign({ qrcodeScene: null }),
  setCurrentFloor: assign({
    currentFloor: (_, { currentFloor }) => currentFloor || getMapPath() || FLOOR_PATH.F1,
  }),
  setKrpanoExecuted: assign({ hasKrpanoEverExecuted: true }),
  cleanVisitedPanoList: assign({ visitedPanoList: [] }),
  setVisitedPanoList: assign({
    visitedPanoList: ({ visitedPanoList }, { scene }) =>
      visitedPanoList.some((s) => s === scene) ? visitedPanoList : [...visitedPanoList, scene],
  }),
  initVisitedPanoList: assign({
    visitedPanoList: () => {
      if (checkIsLocalStorageExpired()) {
        return []
      }
      const localData = getLocalStorage(LOCAL_STORAGE_KEY.VISITED_PANO_LIST)
      return isArray(localData) ? localData : []
    },
  }),

  setVideoPlayerOpenWithSrc: assign({
    isVideoPlayerOpen: true,
    videoSrc: (_, { videoSrc }) => videoSrc,
  }),
  closeVideoPlayer: assign({
    isVideoPlayerOpen: false,
    videoSrc: '',
  }),

  setARIframeOpenWithSrc: assign({
    isArIframeOpen: true,
    arSrc: (_, { arSrc }) => arSrc,
  }),
  closeARIframe: assign({
    isArIframeOpen: false,
    arSrc: '',
  }),

  setKrpanoInterface: assign({
    callKrpano: (_, { callKrpano }) => callKrpano,
    getKrpano: (_, { getKrpano }) => getKrpano,
    setKrpano: (_, { setKrpano }) => setKrpano,
  }),
  setPublicFacilityId: assign({ publicFacilityId: (_, { publicFacilityId }) => publicFacilityId }),
  setCurrentScene: assign({ currentScene: (_, { scene }) => scene }),
  setPreviousScene: assign({ previousScene: ({ currentScene }) => currentScene }),
  setShowSpots: assign({ showSpots: (_, { showSpots }) => Boolean(showSpots) }),
  setShowMinimap: assign({ showMinimap: (_, { showMinimap }) => Boolean(showMinimap) }),
  enableShowSpot: assign({ showSpots: true }),
  setSpotName: assign({ spotName: (_, { name }) => name }),
  setNowScene: assign({ nowScene: (_, { scene }) => scene }),
  cleanNowScene: assign({ nowScene: null }),
  cleanSelectedScene: assign({ selectedScene: null, urlScene: null, qrcodeScene: null }),
  setSelectedScene: assign({
    selectedScene: ({ selectedScene }, { scene, qrcodeScene }) => qrcodeScene || scene || selectedScene,
  }),
  setSceneParamToggled: assign({ hasSceneParamToggled: true }),
  setSceneParamNotToggled: assign({ hasSceneParamToggled: false }),
  initialGA: ({ ReactGA }) => {
    ReactGA.initialize(GA_ID, { debug: isDevMode })
    ReactGA.pageview(window.location.pathname + window.location.search)
  },
}

const krpanoActions = {
  markVisitedPanos: ({ getKrpano, setKrpano, previousScene }) => {
    if (!getKrpano) return
    const hotspots = getKrpano('global.hotspot').getArray()
    for (let i = 0; i < hotspots.length; i++) {
      const hotspotName = hotspots[i]?.name
      const hotspotUrl = hotspots[i]?.url
      if (previousScene === hotspotName) {
        console.log(getVisitedArrowImg(hotspotUrl))
        setKrpano(`hotspot['${hotspotName}'].url`, getVisitedArrowImg(hotspotUrl))
      }
    }
  },
  toggleSpotVisibility: ({ showSpots, getKrpano, setKrpano }, e) => {
    if (!getKrpano) return

    const shouldShowSpots = e?.showSpots ?? showSpots
    const hotspots = getKrpano('global.hotspot').getArray()
    for (let i = 0; i < hotspots.length; i++) {
      const hotspotName = hotspots[i]?.name
      const spotType = hotspots[i]?.spot_type
      if (spotType === TOUR || spotType === PHOTO) {
        setKrpano(`hotspot['${hotspotName}'].visible`, shouldShowSpots ? '1' : '0')
      }
    }
  },
  loadScene: ({ hasKrpanoEverExecuted, callKrpano }, { scene }) => {
    if (hasKrpanoEverExecuted && scene) {
      callKrpano(`loadscene(${scene}, null, MERGE)`)
    }
  },
  stopAutoplayVideo: ({ callKrpano, hasKrpanoEverExecuted }) => {
    if (callKrpano && hasKrpanoEverExecuted) {
      callKrpano(KRPANO_ACTION.PAUSE_INTRO_VIDEO())
    }
  },
  handleAutoplayVideo: ({ callKrpano, currentScene }) => {
    if (callKrpano) {
      if (SCENE_WITH_AUTOPLAY_VIDEO === currentScene) {
        callKrpano(KRPANO_ACTION.PLAY_INTRO_VIDEO())
      } else {
        callKrpano(KRPANO_ACTION.PAUSE_INTRO_VIDEO())
      }
    }
  },
}

const historyActions = {
  initVisitedLocalStorageHistory: () => {
    if (checkIsLocalStorageExpired()) {
      setLocalStorage(LOCAL_STORAGE_KEY.VISITED_DAY, getCurrentDate())
      setLocalStorage(LOCAL_STORAGE_KEY.VISITED_PANO_LIST, [])
    }
  },
  updateVisitedLocalStorageHistory: ({ visitedPanoList }, { scene }) => {
    const newList = visitedPanoList.some((s) => s === scene)
      ? visitedPanoList
      : [...visitedPanoList, scene]
    setLocalStorage(LOCAL_STORAGE_KEY.VISITED_PANO_LIST, newList)
  },
}

export default {
  ...routeActions,
  ...setActions,
  ...krpanoActions,
  ...historyActions,
}
