import React from 'react'
import { useMainContext } from 'context/main'
import { getPageState } from 'utils/xstate'
import { PAGE_STATUS } from 'constants/xstate'

import Home from 'components/home'
import Map from 'components/map'
import Overview from 'components/overview'
import Pano from 'components/pano'

import useLeaveLineWebview from 'hooks/common/useLeaveLineWebview'

const App = () => {
  useLeaveLineWebview()
  const {
    state,
    context: { hasKrpanoEverExecuted },
  } = useMainContext()

  const pageStatus = getPageState(state.value)

  return (
    <div className="page">
      {pageStatus === PAGE_STATUS.HOME && <Home />}
      {pageStatus === PAGE_STATUS.MAP && <Map />}
      {pageStatus === PAGE_STATUS.OVERVIEW && <Overview />}
      {(pageStatus === PAGE_STATUS.PANO || hasKrpanoEverExecuted) && <Pano />}
    </div>
  )
}

export default App
