import { FLOOR_PATH } from 'constants/path'
import { FLOOR_MAP } from 'config/map'
import { values } from 'lodash'

export const validQrcodeResult = (str) => {
  if (!str) return null

  const url = new URL(str)
  const paths = url.pathname.split('/')
  const qrcodeScene = url.searchParams.get('scene')
  const qrcodeSearchParamStr = url.search

  if (
    !qrcodeScene ||
    paths.length <= 2 ||
    paths[1] !== 'map' ||
    !values(FLOOR_PATH).includes(`/${paths[2]}`)
  ) {
    return null
  }

  const qrcodeFloor = `/${paths[2]}`

  const vrEntry = FLOOR_MAP[qrcodeFloor]?.vrEntry.find(({ sceneName }) => sceneName === qrcodeScene)
  if (!vrEntry) return null

  return { qrcodeScene, qrcodeFloor, qrcodeSearchParamStr }
}

export const getMapSize = ({
  naturalMapHeight = 0,
  naturalMapWidth = 0,
  viewportHeight = 0,
  headerHeight = 44,
  maxMapHeight = 520,
}) => {
  const actualMapHeight = getMapImageHeight({ maxMapHeight, viewportHeight, headerHeight })
  const actualMapWidth = (actualMapHeight * naturalMapWidth) / naturalMapHeight

  return {
    actualMapHeight,
    actualMapWidth,
  }
}

export const getMapImageHeight = ({ maxMapHeight, viewportHeight, headerHeight }) => {
  const visibleArea = viewportHeight - headerHeight
  return visibleArea >= maxMapHeight ? maxMapHeight : visibleArea
}

export const getMapCenterX = ({ viewportWidth, actualMapWidth, scale = 1 }) => {
  return (viewportWidth - actualMapWidth * scale) / 2
}

export const getMapMinX = ({ viewportWidth, actualMapWidth, scale = 1 }) => {
  return viewportWidth - actualMapWidth * scale
}
