import React, { useRef, useEffect } from 'react'
import { useSpring, a } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import { SPOT_REVIEW } from 'config/krpano'
import useViewportSize from 'hooks/common/useViewportSize'
import { getImagePath } from 'utils/krpano'
import { clamp } from 'lodash'
import { useMainContext } from 'context/main'

import Carousel from 'components/pano/common/carousel'

const PREVIEW_OPEN_CONFIG = { mass: 1, tension: 80, friction: 15 }
const TEXT_OPEN_CONFIG = { mass: 1, tension: 74, friction: 15 }

const PREVIEW_CLOSE_CONFIG = { mass: 1, tension: 90, friction: 14 }
const TEXT_CLOSE_CONFIG = { mass: 1, tension: 84, friction: 14 }

const SCALE_CONFIG = { mass: 1, tension: 500, clamp: true }

const TourSpot = () => {
  const { height: viewportHeight, width: viewportWidth } = useViewportSize()
  const firstImgRef = useRef(null)
  const minPreviewHeight = (viewportWidth * 9) / 16
  const maxPreviewHeight = viewportWidth

  const {
    send,
    state,
    context: { spotName },
  } = useMainContext()

  const isPanoState = state.matches('pano')
  const isModalOpenState = state.matches('pano.tourSpotModal.open')
  const shoulsOpenModal = isModalOpenState && Boolean(spotName)

  const [{ y: previewY }, setPreviewY] = useSpring(() => ({
    y: viewportHeight,
  }))
  const [{ height: previewHeight }, setPreviewHeight] = useSpring(() => ({
    height: viewportWidth,
  }))
  const [{ y: introY }, setIntroY] = useSpring(() => ({ y: viewportHeight }))

  const open = () => {
    setPreviewY({ y: 0, config: PREVIEW_OPEN_CONFIG })
    setIntroY({ y: viewportWidth, config: TEXT_OPEN_CONFIG })
  }

  const close = (velocity = 0) => {
    setPreviewY({ y: viewportHeight, config: PREVIEW_CLOSE_CONFIG })
    setIntroY({ y: viewportHeight, config: TEXT_CLOSE_CONFIG })
    send({ type: 'CLOSE_TOUR_SPOT_MODAL' })
  }

  const bind = useDrag(
    ({ first, last, vxvy: [, vy], movement: [, my], cancel, canceled }) => {
      if (!last) {
        const newPreviewHeight = clamp(my, minPreviewHeight, maxPreviewHeight)
        setPreviewHeight({
          height: newPreviewHeight,
          immediate: true,
          config: SCALE_CONFIG,
        })
        setIntroY({
          y: newPreviewHeight,
          immediate: true,
          config: SCALE_CONFIG,
        })
      }
    },
    {
      initial: () => [0, previewHeight.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  )

  useEffect(() => {
    if (shoulsOpenModal) {
      open()
      setPreviewHeight({ height: viewportWidth })
    }
  }, [shoulsOpenModal]) // eslint-disable-line

  const display = previewY.to((py) => (py < viewportHeight && isPanoState ? 'block' : 'none'))

  const { title = '', description = '', images = [] } = SPOT_REVIEW[spotName] ?? {}

  return (
    <>
      <a.div className="tour-spot-preview" style={{ y: previewY, height: viewportHeight, display }}>
        <Carousel
          wrapperStyles={{ height: previewHeight }}
          wrapperClassName="tour-spot-preview__wrapper"
          showCloseIcon={true}
          images={images}
          handleCloseClick={close}
          spotType="tour"
        />
      </a.div>
      <a.div
        className="tour-spot-intro"
        style={{ y: introY, height: viewportHeight, display }}
        {...bind()}
      >
        <div className="tour-spot-intro__title">
          <div>{title}</div>
        </div>
        <div className="tour-spot-intro__description">{description}</div>
      </a.div>
      {/* A workaround for getting first image size */}
      <img
        src={getImagePath(images[0], 'tour')}
        alt="demo"
        ref={firstImgRef}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default TourSpot
