import { useMainContext } from 'context/main'
import { FLOOR_MAP } from 'config/map'

import InfoBtn from './infoBtn'
import ScanBtn from './scanBtn'
import FloorSwitcher from './floorSwitcher'

const Header = ({ goToOverviewPage }) => {
  const {
    send,
    context: { currentFloor },
  } = useMainContext()

  const handleSearchClick = () => send('OPEN_MARKERS_MENU')
  const handleQrcodeClick = () => send('OPEN_QRCODE_MODAL')

  const { text } = FLOOR_MAP[currentFloor]

  return (
    <div className="map-header disable-select">
      <ScanBtn onClick={handleQrcodeClick} />
      <FloorSwitcher />
      <InfoBtn text={text} onClick={handleSearchClick} />
    </div>
  )
}

export default Header
