import infoImg from 'assets/images/map/icon-search.svg'

const InfoBtn = ({ onClick }) => {
  return (
    <div className="info-icon" onClick={onClick}>
      <img src={infoImg} alt="scan" />
    </div>
  )
}

export default InfoBtn
