import krpanoSpots from 'config/krpano-spots.json'

export const KRPANO_ACTION = {
  PAUSE_INTRO_VIDEO: () => 'hotspot[hotspot_2].pause();',
  PLAY_INTRO_VIDEO: () => 'hotspot[hotspot_2].play();',
  LOOT_TO: (hl = 0, vl = 0) => `lookto(${hl}, ${vl})`,
}

export const SPOT_REVIEW = krpanoSpots

export const TOUR_IMAGE_PATH = '/krpano/img/spots/'
export const PHOTO_IMAGE_PATH = '/krpano/img/photo/'

export const SCENE_WITH_AUTOPLAY_VIDEO = 'scene_2f_02'
