import history from 'config/history'
import { PAGE_STATUS } from 'constants/xstate'
import { MARKER_STATUS } from 'constants/xstate'

const { ELEVATOR, STAIR, WC, WATER } = MARKER_STATUS
const markers = [ELEVATOR, STAIR, WC, WATER]

const guards = {
  isOverviewRoute: () => history.location.pathname.split('/')[1] === PAGE_STATUS.OVERVIEW,
  isMapRoute: () => history.location.pathname.split('/')[1] === PAGE_STATUS.MAP,
  isPanoRoute: () => history.location.pathname.split('/')[1] === PAGE_STATUS.PANO,

  hasNoEnableMarker: (_, __, { state: { value } }) => {
    return !markers.some((key) => value?.map?.[key] === 'enable')
  },
  isSameFloor: ({ currentFloor }, { currentFloor: newFloor }) => newFloor === currentFloor,
}

export default guards
