import { useEffect, useState, useRef } from 'react'
import { useSpring, a } from 'react-spring'
import { useEffectOnce } from 'react-use'
import { useMainContext } from 'context/main'
import { forceCameraStop } from 'utils/camera'
import { validQrcodeResult } from 'utils/map'
import QrReader from 'react-qr-reader'
import useViewportSize from 'hooks/common/useViewportSize'

import demoImg from 'assets/images/map/qrThumb.jpg'
// import history from 'config/history'

const MODAL_OPEN_CONFIG = { mass: 1, tension: 100, friction: 16 }
const MODAL_CLOSE_CONFIG = { mass: 1, tension: 90, friction: 14 }

const Scanner = () => {
  const { send } = useMainContext()

  const [, setHasError] = useState(false)
  const readerRef = useRef(null)

  useEffectOnce(() => {
    return () => {
      forceCameraStop()
    }
  })

  const [, setLoaded] = useState(false)

  const handleScan = (url) => {
    const result = validQrcodeResult(url)
    if (!result) return

    const { qrcodeScene, qrcodeFloor, qrcodeSearchParamStr } = result
    send({
      type: 'SET_AND_CLOSE_QRCODE_MODAL',
      qrcodeScene,
      currentFloor: qrcodeFloor,
      qrcodeSearchParamStr,
    })
  }

  const onLoad = () => {
    setLoaded(true)
  }

  const setCameraError = () => setHasError(true)

  // TODO: Handle when the wrong qrcode is captured
  return (
    <div className="qr-reader-wrapper">
      <QrReader
        ref={readerRef}
        delay={400}
        onError={setCameraError}
        onLoad={onLoad}
        onScan={handleScan}
        style={{ width: '90%', height: '90%' }}
      />
    </div>
  )
}

const QrcodeModal = () => {
  const { height: viewportHeight } = useViewportSize()
  const [isLegacyMode, setIsLegacyMode] = useState(false)
  const legacyCameraRef = useRef(null)

  const { send, state } = useMainContext()

  const [{ y: modealY }, setModalY] = useSpring(() => ({ y: viewportHeight }))

  const isMapState = state.matches('map')
  const isModalClosed = state.matches('map.qrModal.close')
  const isReaderOpen = state.matches('map.qrModal.openCamera')

  const { transform } = useSpring({
    transform: `translate3d(0, ${isModalClosed ? viewportHeight : 0}px, 0)`,
    config: MODAL_OPEN_CONFIG,
  })

  useEffect(() => {
    if (isModalClosed) {
      setModalY({ y: viewportHeight, config: MODAL_CLOSE_CONFIG })
    } else {
      setModalY({ y: 0, config: MODAL_OPEN_CONFIG })
    }
  }, [isModalClosed]) // eslint-disable-line

  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      setIsLegacyMode(true)
    }
  }, [])

  const display = modealY.to((py) => (py < viewportHeight ? 'block' : 'none'))

  const handleLegacyCameraScan = (url) => {
    const result = validQrcodeResult(url)
    if (!result) return

    const { qrcodeScene, qrcodeFloor, qrcodeSearchParamStr } = result
    send({
      type: 'SET_AND_CLOSE_QRCODE_MODAL',
      qrcodeScene,
      currentFloor: qrcodeFloor,
      qrcodeSearchParamStr,
    })
  }

  const handleCloseClick = () => send({ type: 'CLOSE_QRCODE_MODAL' })
  const handleOpenScannerClick = () => {
    if (isLegacyMode) {
      if (legacyCameraRef.current) {
        legacyCameraRef.current.openImageDialog()
      }
    } else {
      send({ type: 'OPEN_QRCODE_CAMERA' })
    }
  }

  if (!isMapState) return null

  return (
    <a.div className="qr-code-modal" style={{ transform, height: viewportHeight, display }}>
      <div className="close-icon-wrapper" onClick={handleCloseClick}>
        <div className="modal-close-icon" />
      </div>
      <img className="demo-qr-code-image" src={demoImg} alt="demo-qrcode-img" />
      <div className="qr-code-modal__content">
        <div className="qr-code-modal__content__title">
          找一找、掃一掃，
          <br /> 一同來尋寶。
        </div>
        <div className="qr-code-modal__content__hint">
          當你在館內看到上方的QRcode你可以「掃描」來探索更多附近的彩蛋！
        </div>
        {!isModalClosed && isLegacyMode && (
          <QrReader
            ref={legacyCameraRef}
            delay={1000}
            onScan={handleLegacyCameraScan}
            style={{ display: 'none' }}
            legacyMode={true}
          />
        )}
        <input
          type="button"
          className="qr-code-modal__content__button"
          value="開啟鏡頭"
          onClick={handleOpenScannerClick}
        />
      </div>
      {isReaderOpen && !isLegacyMode && <Scanner />}
    </a.div>
  )
}

export default QrcodeModal
