// ref: https://github.com/JodusNodus/react-qr-reader/issues/156
export const forceCameraStop = () => {
  const videoEl = document.getElementsByTagName('video')[0]
  if (!videoEl) return

  try {
    let stream = videoEl.srcObject
    let tracks = stream.getTracks()
    tracks.forEach((track) => {
      track.stop()
    })
    videoEl.srcObject = null
  } catch (e) {
    console.error(`error when descturing camera`, e)
  }
}
