import { useEffect, useState } from 'react'
import { useSpring, a } from 'react-spring'
import { useMainContext } from 'context/main'
import { PUBLIC_FACILITY } from 'config/publicFacility'
import useViewportSize from 'hooks/common/useViewportSize'

const PF_IMAGE_PATH = '/publicFacility/image/'

const MODAL_OPEN_CONFIG = { mass: 1, tension: 100, friction: 16 }

const cachedImageSource = []

const PublicFacilityModal = () => {
  const [imgLoaded, setImgLoaded] = useState(false)
  const { height: viewportHeight } = useViewportSize()
  const [shouldOpenModal, setShouldOpenModal] = useState(false)
  const {
    send,
    state,
    context: { publicFacilityId },
  } = useMainContext()

  const isModalClosed = state.matches('map.publicFacilityModal.close')
  const handleImgLoaded = () => setImgLoaded(true)

  const { transform } = useSpring({
    transform: `translate3d(0, ${!shouldOpenModal ? viewportHeight : 0}px, 0)`,
    config: MODAL_OPEN_CONFIG,
  })

  useEffect(() => {
    if (isModalClosed) {
      setShouldOpenModal(false)
    } else {
      setTimeout(() => setShouldOpenModal(true), 50)
    }
  }, [isModalClosed]) // eslint-disable-line

  useEffect(() => {
    const filename = PUBLIC_FACILITY?.[publicFacilityId]?.image ?? ''

    if (!cachedImageSource.includes(filename)) {
      cachedImageSource.push(filename)
      setImgLoaded(false)
    }
  }, [publicFacilityId]) // eslint-disable-line

  const handleCloseClick = () => send({ type: 'CLOSE_PUBLIC_FACILITY_MODAL' })

  const title = PUBLIC_FACILITY?.[publicFacilityId]?.title ?? ''
  const description = PUBLIC_FACILITY?.[publicFacilityId]?.description ?? ''
  const filename = PUBLIC_FACILITY?.[publicFacilityId]?.image ?? ''
  const imgSrc = `${PF_IMAGE_PATH}${filename}.jpg`

  return (
    <a.div className="public-facility-modal" style={{ transform, height: viewportHeight }}>
      <div className="close-icon-wrapper" onClick={handleCloseClick}>
        <div className="modal-close-icon" />
      </div>
      <img
        className="public-facility-modal__image"
        src={imgSrc}
        key={imgSrc}
        alt="public-facility"
        style={{ opacity: imgLoaded ? 1 : 0 }}
        onLoad={handleImgLoaded}
      />
      <div className="public-facility-modal__content">
        <div className="public-facility-modal__content__title">{title}</div>
        <div className="public-facility-modal__content__description">{description}</div>
      </div>
    </a.div>
  )
}

export default PublicFacilityModal
