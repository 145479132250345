import { SPOT_REVIEW, TOUR_IMAGE_PATH, PHOTO_IMAGE_PATH } from 'config/krpano'
import { PANO_SPOT_TYPE } from 'constants/base'

export const getSpotText = (spotName = '') => {
  return SPOT_REVIEW?.[spotName]?.title ?? ''
}

export const getFloorFromScene = (sceneName) => {
  if (!sceneName) return ''
  return (sceneName.split('_')?.[1] ?? '').toLocaleLowerCase()
}

export const getMenuBtnStyle = (isPressed, isSelected) => {
  if (isPressed) return { opacity: 0.8, bgColor: '#3A3A3A' }
  if (isSelected) return { opacity: 0.8, bgColor: '#BE8A46' }
  return { opacity: 0.5, bgColor: '#3A3A3A' }
}

export const getImagePath = (fileName, spotType) => {
  if (spotType === PANO_SPOT_TYPE.TOUR) return `${TOUR_IMAGE_PATH}${fileName}`
  return `${PHOTO_IMAGE_PATH}${fileName}`
}

export const getVisitedArrowImg = (url = '') => {
  return url.replace('.png', '-visited.png')
}
