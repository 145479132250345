import moment from 'moment'
import { toPairs, isObject, isEmpty, values } from 'lodash'
import { LOCAL_STORAGE_KEY, IOS_USER_AGENT_LIST } from 'constants/base'
import { FLOOR_PATH } from 'constants/path'
import history from 'config/history'

export const getSearchParamString = (params = {}) => {
  if (isEmpty(params)) return ''

  const pairs = toPairs(params).map(([key, value]) => `${key}=${value}`)
  return `?${pairs.join('&')}`
}

export const parseSearchParams = (searchQuery = '') => {
  const searchParams = new URLSearchParams(searchQuery)

  return [...searchParams.entries()].reduce((result, [key, value]) => ({ ...result, [key]: value }), {})
}

export const parseUrlHash = (hash = '#') => {
  if (!Boolean(hash) || hash === '#' || !hash.includes('#')) return null
  return hash.substr(1)
}

export const getMapPath = () => {
  const paths = history.location.pathname.split('/')
  if (paths.length <= 2) return null

  const secondPath = `/${paths[2]}`
  if (!values(FLOOR_PATH).includes(secondPath)) return null

  return secondPath
}

export const isEmptyObject = (obj) => isObject(obj) && isEmpty(obj)

export const getCurrentDate = (format = 'l') => {
  return moment().format(format)
}

export const buildThresholdList = (numSteps = 100) => {
  const thresholds = []

  for (let i = 1.0; i <= numSteps; i++) {
    let ratio = i / numSteps
    thresholds.push(ratio)
  }

  thresholds.push(0)
  return thresholds
}

export const getFirstPath = (url = '/') => {
  return `/${url.split('/')[1]}`
}

export const getFloorPath = (url = '/', defaultFloor = '/') => {
  const pathArr = url.split('/')
  if (pathArr.length <= 2) return defaultFloor
  return `/${pathArr[2]}`
}

export const getLocalStorage = (key = '') => {
  const result = localStorage.getItem(key)
  return JSON.parse(result)
}

export const setLocalStorage = (key, value = '') => {
  const encodedValue = JSON.stringify(value)
  localStorage.setItem(key, encodedValue)
  return encodedValue
}

export const removeLocalStorage = (key = '') => {
  return localStorage.removeItem(key)
}

export const checkIsLocalStorageExpired = () => {
  const visitedDay = getLocalStorage(LOCAL_STORAGE_KEY.VISITED_DAY)
  const day = getCurrentDate()
  return visitedDay !== day
}

export const isIos = () => {
  return (
    IOS_USER_AGENT_LIST.includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}
