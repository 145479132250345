import { useState, useEffect } from 'react'
import { useMainContext } from 'context/main'
import { getFloorFromScene } from 'utils/krpano'

import backIcon from 'assets/images/pano/icon40px_back_white.svg'
import moreIcon from 'assets/images/pano/icon_more.svg'

const PanoController = () => {
  const {
    send,
    state,
    context: { currentFloor, currentScene },
  } = useMainContext()

  const [isFadeOut, setIsFadeOut] = useState(false)

  const isPanoState = state.matches('pano')
  const isIdleState = state.matches('pano.krpano.idle')

  useEffect(() => {
    if (isPanoState) {
      setIsFadeOut(false)
    }
  }, [isPanoState]) // eslint-disable-line

  if (!isPanoState || isIdleState) return null

  // Check if previous floor of map is different from the floor in krpano
  const backToMap = () => {
    if (!currentScene) {
      send('BACK_TO_MAP_PAGE')
    } else {
      const krpanoFloorKey = `/${getFloorFromScene(currentScene)}`
      if (krpanoFloorKey === currentFloor) {
        send({ type: 'BACK_TO_MAP_PAGE', currentFloor, scene: currentScene, keepHash: false })
      } else {
        send({ type: 'GO_TO_NEW_MAP_PAGE', currentFloor: krpanoFloorKey, scene: currentScene, keepHash: false })
      }
    }
  }

  const openSwitchModal = () => {
    send('OPEN_SPOT_SWITCH_MODAL')
  }

  const startFadeOut = () => setIsFadeOut(true)

  return (
    <>
      <div className="pano-controller">
        <div className="pano-controller__to-map" onClick={startFadeOut}>
          <img src={backIcon} alt="back" />
        </div>
        <div className="pano-controller__toggle-spot" onClick={openSwitchModal}>
          <img src={moreIcon} alt="more" />
        </div>
      </div>
      {isFadeOut && <div className="pano-fade-out-mask" onAnimationEnd={backToMap} />}
    </>
  )
}

export default PanoController
