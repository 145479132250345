import { PAGE_STATUS } from './xstate'

export const PATH = {
  [PAGE_STATUS.HOME]: '/',
  [PAGE_STATUS.OVERVIEW]: '/overview',
  [PAGE_STATUS.MAP]: '/map',
  [PAGE_STATUS.PANO]: '/pano',
}

export const FLOOR_PATH = {
  F1: '/1f',
  F2: '/2f',
  F5: '/5f',
  F6: '/6f',
}
