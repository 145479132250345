import { FLOOR_MAP } from 'config/map'
import { toPairs, keys } from 'lodash'
import { useMainContext } from 'context/main'

const FloorSwitcher = ({ onClick }) => {
  const {
    send,
    context: { currentFloor },
  } = useMainContext()

  const handleFloorClick = (nowFloor) => () => {
    if (currentFloor !== nowFloor) {
      send({ type: 'SWITCH_FLOOR', currentFloor: nowFloor })
    }
  }

  const selectedIndex = keys(FLOOR_MAP).indexOf(currentFloor)
  const placeholderTranslate = `translateX(calc((100vw - 108px) * ${selectedIndex} / 4))`
  return (
    <div className="floor-switcher" onClick={onClick}>
      <div className="floor-switcher__select-placeholder" style={{ transform: placeholderTranslate }} />
      {toPairs(FLOOR_MAP).map(([floorKey, { text }]) => {
        const textClasses =
          currentFloor === floorKey
            ? 'floor-switcher__floor floor-switcher__floor--selected'
            : 'floor-switcher__floor'
        return (
          <div key={floorKey} className={textClasses} onClick={handleFloorClick(floorKey)}>
            {text}
          </div>
        )
      })}
    </div>
  )
}

export default FloorSwitcher
