export const isDevMode = process.env.NODE_ENV === 'development'

export const AVAILABLE_FLOORS = [6, 5, 2, 1]

export const PANO_SPOT_TYPE = {
  TOUR: 'tour',
  PHOTO: 'photo',
}

export const PHOTO_SPOT_PICTURE_TYPE = {
  HORIZONTAL: 'H',
  VERTICAL: 'V',
}

export const KRPANO_NULL = 'null'

export const MENU_BTN_STATUS = {
  IDLE: 'IDLE',
  PRESSED: 'PRESSED',
  SELECTED: 'SELECTED',
}

export const CUSTOM_KRPANO_EMBEDDING_PARAMS = {
  swf: 'krpano/krpano.swf',
  initvars: { design: 'flat' },
  consolelog: isDevMode,
}

export const LOCAL_STORAGE_KEY = {
  VISITED_PANO_LIST: 'visited-pano-list',
  VISITED_DAY: 'visited-day',
}


export const IOS_USER_AGENT_LIST = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
]

