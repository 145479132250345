const F1 = {
  stair_1f_01: {
    title: '一樓樓梯',
    description: '此樓梯可通往大劇院。',
    image: '1f-st-01',
  },
  stair_1f_02: {
    title: '一樓樓梯',
    description: '此樓梯可通往小劇場。',
    image: '1f-st-02',
  },
  stair_1f_03: {
    title: '一樓樓梯',
    description: '此樓梯可通往中劇院。',
    image: '1f-st-03',
  },
  stair_1f_04: {
    title: '一樓樓梯',
    description: '此樓梯可通往B1(停車場)~B2',
    image: '1f-st-04',
  },
  stair_1f_05: {
    title: '一樓樓梯',
    description: '此樓梯可通往大劇院、中劇院。',
    image: '1f-st-05',
  },
  //
  elevator_1f_01: {
    title: '一樓電梯',
    description: '此電梯可前往1F~B2。',
    image: '1f-el-01',
  },
  elevator_1f_02: {
    title: '一樓電梯',
    description: '此電梯可前往2F。',
    image: '1f-el-02',
  },
  elevator_1f_03: {
    title: '一樓電梯',
    description: '此區電梯可前往B1~6F。',
    image: '1f-el-03',
  },
  elevator_1f_04: {
    title: '一樓電梯',
    description: '此電梯可前往小劇場。',
    image: '1f-el-04',
  },
  wc_1f_01: {
    title: '一樓廁所',
    description: '男/女洗手間及親子廁格。',
    image: '1f-wc-01',
  },
  wc_1f_02: {
    title: '一樓廁所',
    description: '男/女、無障礙洗手間及親子廁格。',
    image: '1f-wc-02',
  },
  wc_1f_03: {
    title: '一樓廁所',
    description: '男/女、無障礙洗手間及親子廁格。',
    image: '1f-wc-02',
  },
  water_1f_01: {
    title: '一樓飲水機',
    description: '此區設有3台飲水機。',
    image: '1f-dr-01',
  },
  //
  locker_1f_01: {
    title: '一樓投幣式置物櫃',
    description: '小型置物10元/次，大型置物20次/元(22吋以上行李請洽服務台)。',
    image: '1f-lo-01',
  },
  breastfeeding_1f_01: {
    title: '一樓哺集乳室',
    description: '內附設飲水機/尿布檯/洗手檯/沙發等。',
    image: '1f-nu-01',
  },
}

const F2 = {
  stair_2f_01: {
    title: '二樓樓梯',
    description: '此樓梯可通往1F大廳。',
    image: '2f-st-01',
  },
  stair_2f_02: {
    title: '二樓樓梯',
    description: '此樓梯可通往5F。',
    image: '2f-st-02',
  },
  stair_2f_03: {
    title: '二樓樓梯',
    description: '此樓梯可前往1F大廳。',
    image: '2f-st-03',
  },
  stair_2f_04: {
    title: '二樓樓梯',
    description: '此樓梯可通往1F大廳。',
    image: '2f-st-04',
  },
  //
  elevator_2f_01: {
    title: '二樓電梯',
    description: '此電梯可前往1F大廳。',
    image: '2f-el-01',
  },
  elevator_2f_02: {
    title: '二樓電梯',
    description: '此區電梯可通往B1~6F。',
    image: '2f-el-02',
  },
  //
  wc_2f_01: {
    title: '二樓廁所',
    description: '男/女、無障礙洗手間及親子廁格。',
    image: '2f-wc-01',
  },
  wc_2f_02: {
    title: '二樓廁所',
    description: '男/女、無障礙洗手間及親子廁格。',
    image: '2f-wc-01',
  },
  wc_2f_03: {
    title: '二樓廁所',
    description: '無障礙洗手間。',
    image: '2f-wc-02',
  },
  wc_2f_04: {
    title: '二樓廁所',
    description: '男/女洗手間及親子廁格。',
    image: '2f-wc-03',
  },
  water_2f_01: {
    title: '二樓飲水機',
    description: '此區設有1台飲水機。',
    image: '2f-dr-01',
  },
}

const F5 = {
  stair_5f_01: {
    title: '五樓樓梯',
    description: '此樓梯可通往6F。',
    image: '5f-st-01',
  },
  stair_5f_02: {
    title: '五樓樓梯',
    description: '此樓梯可通往2F。',
    image: '5f-st-02',
  },
  //
  elevator_5f_01: {
    title: '五樓電梯',
    description: '此區電梯可前往B1~6F。',
    image: '5f-el-01',
  },
  //
  wc_5f_01: {
    title: '五樓廁所',
    description: '男/女、無障礙及親子廁格。',
    image: '5f-wc-01',
  },
  wc_5f_02: {
    title: '五樓廁所',
    description: '男/女、無障礙及親子廁格。',
    image: '5f-wc-01',
  },
  wc_5f_03: {
    title: '五樓廁所',
    description: '男/女洗手間及親子廁格。',
    image: '5f-wc-02',
  },
}

const F6 = {
  stair_6f_01: {
    title: '六樓樓梯',
    description: '此樓梯可通往5F。',
    image: '6f-st-01',
  },
  //
  elevator_6f_01: {
    title: '六樓電梯',
    description: '此區電梯可前往B1~6F。',
    image: '6f-el-01',
  },
  //
  wc_6f_01: {
    title: '六樓廁所',
    description: '男/女洗手間。',
    image: '6f-wc-01',
  },
}

export const PUBLIC_FACILITY = {
  ...F1,
  ...F2,
  ...F5,
  ...F6,
}
