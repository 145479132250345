import { FLOOR_PATH } from 'constants/path'
import { MARKER_STATUS } from 'constants/xstate'

import waterLargerIcon from 'assets/images/map/markers/wf.svg'
import elevatorLargerIcon from 'assets/images/map/markers/e.svg'
import stairLargerIcon from 'assets/images/map/markers/s.svg'
import wcLargerIcon from 'assets/images/map/markers/wc.svg'

import introduceIcon from 'assets/images/map/entries/mk_introduce.svg'
import ticketIcon from 'assets/images/map/entries/mk_ticket.svg'
import infoIcon from 'assets/images/map/entries/mk_i.svg'
import shopIcon from 'assets/images/map/entries/mk_shop.svg'
import drinkIcon from 'assets/images/map/entries/mk_drink.svg'
import foodIcon from 'assets/images/map/entries/mk_food.svg'

import clockIcon from 'assets/images/map/entries/mk_clock.png'
import pianIcon from 'assets/images/map/entries/mk_pian.png'

import f1MapImg from 'assets/images/map/1f/map.png'
import f1ElevatorImg from 'assets/images/map/1f/e.svg'
import f1StairImg from 'assets/images/map/1f/s.svg'
import f1WCImg from 'assets/images/map/1f/wc.svg'
import f1WaterImg from 'assets/images/map/1f/wf.svg'

import f2MapImg from 'assets/images/map/2f/map.png'
import f2ElevatorImg from 'assets/images/map/2f/e.svg'
import f2StairImg from 'assets/images/map/2f/s.svg'
import f2WCImg from 'assets/images/map/2f/wc.svg'
import f2WaterImg from 'assets/images/map/2f/wf.svg'

import f5MapImg from 'assets/images/map/5f/map.png'
import f5ElevatorImg from 'assets/images/map/5f/e.svg'
import f5StairImg from 'assets/images/map/5f/s.svg'
import f5WCImg from 'assets/images/map/5f/wc.svg'

import f6MapImg from 'assets/images/map/6f/map.png'
import f6ElevatorImg from 'assets/images/map/6f/e.svg'
import f6StairImg from 'assets/images/map/6f/s.svg'
import f6WCImg from 'assets/images/map/6f/wc.svg'

import f1MiniMapImg from 'assets/images/pano/minimap/minimap_1f@2x.png'
import f2MiniMapImg from 'assets/images/pano/minimap/minimap_2f@2x.png'
import f5MiniMapImg from 'assets/images/pano/minimap/minimap_5f@2x.png'
import f6MiniMapImg from 'assets/images/pano/minimap/minimap_6f@2x.png'

import elevatorHIcon from 'assets/images/map/pf/e_h.svg'
import elevatorIcon from 'assets/images/map/pf/e.svg'
import lockerIcon from 'assets/images/map/pf/l.svg'
import breastfeedIcon from 'assets/images/map/pf/n.svg'
import stairIcon from 'assets/images/map/pf/s.svg'
import wcIcon from 'assets/images/map/pf/wc.svg'
import wcHIcon from 'assets/images/map/pf/wc_h.svg'
import wcFIcon from 'assets/images/map/pf/wc_f.svg'
import waterIcon from 'assets/images/map/pf/wf.svg'

const { ELEVATOR, STAIR, WC, WATER } = MARKER_STATUS

export const OPTIONAL_MARKER_LIST = [ELEVATOR, STAIR, WC, WATER]

export const PUBLIC_FACILITY = {
  [STAIR]: { icon: stairIcon },
  [ELEVATOR]: { icon: elevatorIcon },
  [WC]: { icon: wcIcon },
  [WATER]: { icon: waterIcon },
}

export const FLOOR_MAP = {
  [FLOOR_PATH.F1]: {
    text: '1F',
    mapSrc: f1MapImg,
    minimapSrc: f1MiniMapImg,
    naturalImageSize: { h: 1563, w: 3420 },
    [ELEVATOR]: f1ElevatorImg,
    [STAIR]: f1StairImg,
    [WC]: f1WCImg,
    [WATER]: f1WaterImg,
    vrEntry: [
      // section - 1
      { coords: [15.64, 31.61], sceneName: 'scene_1f_23' },
      { coords: [17.33, 43.31], sceneName: 'scene_1f_04' },
      { coords: [23.24, 43.31], sceneName: 'scene_1f_18' },
      { coords: [23.31, 24.25], sceneName: 'scene_1f_22' },
      { coords: [30.37, 15.38], sceneName: 'scene_1f_02' },
      { coords: [35.66, 24.75], sceneName: 'scene_1f_21' },
      { coords: [42.25, 18.23], sceneName: 'scene_1f_26' },
      { coords: [51.23, 19.73], sceneName: 'scene_1f_01' },
      { coords: [55.75, 4.85], sceneName: 'scene_1f_14' },
      // section - 2
      { coords: [11.12, 52.17], sceneName: 'scene_1f_25' },
      { coords: [22.85, 60.03], sceneName: 'scene_1f_05', icon: shopIcon },
      { coords: [28.99, 76.09], sceneName: 'scene_1f_19' },
      { coords: [38.19, 72.91], sceneName: 'scene_1f_20' },
      { coords: [39.19, 56.69], sceneName: 'scene_1f_06', icon: ticketIcon },
      { coords: [38.81, 49.14], sceneName: 'scene_1f_06-duplicated', icon: introduceIcon },
      { coords: [44.94, 56.86], sceneName: 'scene_1f_07', icon: clockIcon },
      { coords: [47.01, 34.78], sceneName: 'scene_1f_03', icon: infoIcon },
      { coords: [51.92, 44.98], sceneName: 'scene_1f_08' },
      { coords: [59.36, 43.48], sceneName: 'scene_1f_15', icon: shopIcon },
      { coords: [62.42, 27.42], sceneName: 'scene_1f_13', icon: shopIcon },
      { coords: [76, 11.37], sceneName: 'scene_1f_12', icon: shopIcon },
      // section - 3
      { coords: [53.14, 87.79], sceneName: 'scene_1f_27' },
      { coords: [57.29, 80.1], sceneName: 'scene_1f_09' },
      { coords: [66.26, 64.21], sceneName: 'scene_1f_16', icon: shopIcon },
      { coords: [76.76, 78.43], sceneName: 'scene_1f_10', icon: drinkIcon },
      { coords: [77.3, 58.36], sceneName: 'scene_1f_17', icon: shopIcon },
      { coords: [85.97, 38.96], sceneName: 'scene_1f_11', icon: shopIcon },
      { coords: [92.33, 42.64], sceneName: 'scene_1f_24' },
    ],
    publicFacilities: {
      [STAIR]: [
        { coords: [50.35, 76.76], id: 'stair_1f_01', icon: stairIcon },
        { coords: [26.57, 33.33], id: 'stair_1f_02', icon: stairIcon },
        { coords: [21.59, 18.1], id: 'stair_1f_03', icon: stairIcon },
        { coords: [68.09, 12.67], id: 'stair_1f_04', icon: stairIcon },
        { coords: [39.6, 16.57], id: 'stair_1f_05', icon: stairIcon },
      ],
      [ELEVATOR]: [
        { coords: [64.25, 17.14], id: 'elevator_1f_01', icon: elevatorIcon },
        { coords: [43.09, 77.9], id: 'elevator_1f_02', icon: elevatorIcon },
        { coords: [42.31, 9.52], id: 'elevator_1f_03', icon: elevatorIcon },
        { coords: [19.23, 53.9], id: 'elevator_1f_04', icon: elevatorHIcon },
      ],
      [WC]: [
        { coords: [84.62, 67.43], id: 'wc_1f_01', icon: wcFIcon },
        { coords: [31.73, 58.86], id: 'wc_1f_02', icon: wcHIcon },
        { coords: [29.11, 56.19], id: 'wc_1f_03', icon: wcIcon },
      ],
      [WATER]: [{ coords: [22.99, 48.38], id: 'water_1f_01', icon: waterIcon }],
      others: [
        { coords: [64.25, 9.81], id: 'locker_1f_01', icon: lockerIcon },
        { coords: [29.28, 65.52], id: 'breastfeeding_1f_01', icon: breastfeedIcon },
      ],
    },
  },
  [FLOOR_PATH.F2]: {
    text: '2F',
    mapSrc: f2MapImg,
    minimapSrc: f2MiniMapImg,
    naturalImageSize: { h: 1560, w: 2072 },
    [ELEVATOR]: f2ElevatorImg,
    [STAIR]: f2StairImg,
    [WC]: f2WCImg,
    [WATER]: f2WaterImg,
    vrEntry: [
      { coords: [13.93, 39.83], sceneName: 'scene_2f_02', icon: pianIcon },
      { coords: [14.93, 18.15], sceneName: 'scene_2f_10' },
      { coords: [22.14, 6.77], sceneName: 'scene_2f_01' },
      { coords: [33.33, 6.93], sceneName: 'scene_2f_03' },
      { coords: [42.79, 6.77], sceneName: 'scene_2f_07' },
      { coords: [50.62, 4.95], sceneName: 'scene_2f_08' },
      { coords: [63.56, 14.69], sceneName: 'scene_2f_05' },
      { coords: [74.88, 27.72], sceneName: 'scene_2f_09', icon: shopIcon },
      { coords: [68.28, 46.7], sceneName: 'scene_2f_06' },
      { coords: [63.43, 68.48], sceneName: 'scene_2f_11' },
      { coords: [62.69, 76.9], sceneName: 'scene_2f_04' },
    ],
    publicFacilities: {
      [STAIR]: [
        { coords: [61.82, 83.05], id: 'stair_2f_01', icon: stairIcon },
        { coords: [73.63, 13.2], id: 'stair_2f_02', icon: stairIcon },
        { coords: [66.04, 2.31], id: 'stair_2f_03', icon: stairIcon },
        { coords: [18.39, 2.13], id: 'stair_2f_04', icon: stairIcon },
      ],
      [ELEVATOR]: [
        { coords: [65.8, 82.01], id: 'elevator_2f_01', icon: elevatorIcon },
        { coords: [61.57, 8.25], id: 'elevator_2f_02', icon: elevatorHIcon },
      ],
      [WC]: [
        { coords: [81.09, 75.58], id: 'wc_2f_01', icon: wcIcon },
        { coords: [75.75, 64.19], id: 'wc_2f_02', icon: wcHIcon },
        { coords: [49.38, 15.35], id: 'wc_2f_03', icon: wcHIcon },
        { coords: [14.43, 53.63], id: 'wc_2f_04', icon: wcIcon },
      ],
      [WATER]: [{ coords: [44.53, 18.98], id: 'water_2f_01', icon: waterIcon }],
    },
  },
  [FLOOR_PATH.F5]: {
    text: '5F',
    mapSrc: f5MapImg,
    minimapSrc: f5MiniMapImg,
    naturalImageSize: { h: 1428, w: 3255 },
    [ELEVATOR]: f5ElevatorImg,
    [STAIR]: f5StairImg,
    [WC]: f5WCImg,
    vrEntry: [
      // section - 1
      { coords: [13.34, 56.1], sceneName: 'scene_5f_08', icon: foodIcon },
      { coords: [20.86, 8.89], sceneName: 'scene_5f_07', icon: foodIcon },
      { coords: [32.44, 9.23], sceneName: 'scene_5f_06', icon: foodIcon },
      { coords: [36.2, 31.36], sceneName: 'scene_5f_09', icon: foodIcon },
      { coords: [12.13, 26.36], sceneName: 'scene_5f_17', icon: foodIcon },
      { coords: [45.09, 16.03], sceneName: 'scene_5f_10' },
      { coords: [51.07, 4.18], sceneName: 'scene_5f_12' },
      { coords: [51.53, 14.11], sceneName: 'scene_5f_11' },
      { coords: [51.99, 30.14], sceneName: 'scene_5f_05', icon: shopIcon },
      // section - 2
      { coords: [91.72, 4.7], sceneName: 'scene_5f_16' },
      { coords: [66.49, 5.75], sceneName: 'scene_5f_14' },
      { coords: [62.27, 3.66], sceneName: 'scene_5f_13' },
      { coords: [68.25, 25.61], sceneName: 'scene_5f_15', icon: shopIcon },
      { coords: [62.12, 36.59], sceneName: 'scene_5f_03', icon: shopIcon },
      { coords: [56.83, 49.83], sceneName: 'scene_5f_04', icon: shopIcon },
      { coords: [51.46, 67.42], sceneName: 'scene_5f_02' },
      { coords: [45.63, 78.57], sceneName: 'scene_5f_01' },
    ],
    publicFacilities: {
      [STAIR]: [
        { coords: [56.23, 18.11], id: 'stair_5f_01', icon: stairIcon },
        { coords: [62.29, 11.34], id: 'stair_5f_02', icon: stairIcon },
      ],
      [ELEVATOR]: [{ coords: [44.1, 10.75], id: 'elevator_5f_01', icon: elevatorHIcon }],
      [WC]: [
        { coords: [73.26, 5.66], id: 'wc_5f_01', icon: wcHIcon },
        { coords: [76.41, 5.66], id: 'wc_5f_02', icon: wcIcon },
        { coords: [39.7, 5.66], id: 'wc_5f_03', icon: wcIcon },
      ],
    },
  },
  [FLOOR_PATH.F6]: {
    text: '6F',
    mapSrc: f6MapImg,
    minimapSrc: f6MiniMapImg,
    naturalImageSize: { h: 1428, w: 3233 },
    [ELEVATOR]: f6ElevatorImg,
    [STAIR]: f6StairImg,
    [WC]: f6WCImg,
    vrEntry: [
      { coords: [48.57, 5.91], sceneName: 'scene_6f_01', icon: drinkIcon },
      { coords: [45.02, 42.91], sceneName: 'scene_6f_03' },
      { coords: [60.51, 60.55], sceneName: 'scene_6f_04' },
      { coords: [41.79, 11.59], sceneName: 'scene_6f_05' },
      { coords: [24.77, 29.07], sceneName: 'scene_6f_06' },
    ],
    publicFacilities: {
      [STAIR]: [{ coords: [51.33, 18.91], id: 'stair_6f_01', icon: stairIcon }],
      [ELEVATOR]: [{ coords: [38.87, 7.3], id: 'elevator_6f_01', icon: elevatorHIcon }],
      [WC]: [{ coords: [37.46, 3.93], id: 'wc_6f_01', icon: wcFIcon }],
    },
  },
}

export const MARKER_MAP = {
  [ELEVATOR]: {
    icon: elevatorLargerIcon,
    text: '電梯',
    event: 'TOGGLE_ELEVATOR_MARKER',
  },
  [STAIR]: {
    icon: stairLargerIcon,
    text: '樓梯',
    event: 'TOGGLE_STAIR_MARKER',
  },
  [WC]: {
    icon: wcLargerIcon,
    text: '廁所',
    event: 'TOGGLE_WC_MARKER',
  },
  [WATER]: {
    icon: waterLargerIcon,
    text: '飲水機',
    event: 'TOGGLE_WATER_MARKER',
  },
}
