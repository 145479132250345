export const PAGE_STATUS = {
  HOME: 'home',
  OVERVIEW: 'overview',
  MAP: 'map',
  PANO: 'pano',
}

export const MARKER_STATUS = {
  ENTRY: 'entry',
  ELEVATOR: 'elevator',
  STAIR: 'stair',
  WC: 'wc',
  WATER: 'water',
}

export const KRPANO_STATUS = {
  IDLE: 'idle',
  KRPANO_LOADED: 'krpanoLoaded',
  NEW_SCENE: 'newScene',
  NEW_PANO: 'newPano',
  PREVIEW_COMPLETE: 'previewComplete',
  LOAD_COMPLETE: 'loadComplete',
}
