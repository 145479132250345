import { isString, isObject } from 'lodash'
import { PAGE_STATUS } from 'constants/xstate'

export const getPageState = (stateValue) => {
  if (isObject(stateValue)) {
    return Object.keys(stateValue)?.[0] ?? PAGE_STATUS.HOME
  }

  if (isString(stateValue)) {
    return stateValue
  }

  return PAGE_STATUS.HOME
}
