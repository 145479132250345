import { useEffect, useRef, useState } from 'react'
import { useMainContext } from 'context/main'
import { isIos } from 'utils/base'
import useViewportSize from 'hooks/common/useViewportSize'

const hide = { visibility: 'hidden' }

const COMMON_VIDEO_WIDTH = 607
const COMMON_VIDEO_HEIGHT = 1080

const isIosDevice = isIos()

const VideoPlayer = () => {
  const {
    send,
    context: { isVideoPlayerOpen, videoSrc },
  } = useMainContext()

  const [, setIsReadyForPlaying] = useState(false)
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)

  const handleClose = () => send('CLOSE_VIDEO_PLAYER')

  const { height: viewportHeight, width: viewportWidth } = useViewportSize()
  const shouldFitWidth = viewportWidth / viewportHeight < COMMON_VIDEO_WIDTH / COMMON_VIDEO_HEIGHT

  const videoRef = useRef(null)

  const setVideoPlaying = () => setIsVideoPlaying(true)
  const setVideoPausing = () => setIsVideoPlaying(false)

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setIsReadyForPlaying(true)
    }
  }

  useEffect(() => {
    if (isVideoPlayerOpen && !isIosDevice) {
      playVideo()
    }
  }, [isVideoPlayerOpen])

  useEffect(() => {
    setVideoPausing()
  }, [videoSrc])

  const wrapperClasses = shouldFitWidth
    ? 'video-wrapper video-wrapper--fit-width'
    : 'video-wrapper video-wrapper--fit-height'

  /**
   * Note. 針對ios(iphone5裝置)的行為如下
   * 1. 沒辦法autoPlay => 選擇再製作一層mask當作播放(在onPlay & onPause的時候觸發)
   * 2. 按下播放按鈕後還要等一下下(不確定是不是手機太舊) => 上述提到的Mask還要加一個loading小提示
   */
  return (
    <div key={videoSrc} className={wrapperClasses} style={isVideoPlayerOpen ? {} : hide}>
      <div className="close-icon-wrapper" onClick={handleClose}>
        <div className="modal-close-icon" />
      </div>
      {isIosDevice && !isVideoPlaying && (
        <div className="video-wrapper__ios-mask" onClick={playVideo}>
          <div className="video-wrapper__ios-mask__play-button" />
        </div>
      )}
      <video
        controls={!isIosDevice}
        autoPlay
        ref={videoRef}
        onPlaying={setVideoPlaying}
        onPause={setVideoPausing}
      >
        <source src={videoSrc} type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  )
}

export default VideoPlayer
