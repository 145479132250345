import React, { useRef, useEffect } from 'react'
import { useSprings, a } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import { clamp } from 'lodash'
import { useInView } from 'react-intersection-observer'
import useViewportSize from 'hooks/common/useViewportSize'
import { getImagePath } from 'utils/krpano'
import ImageWrapper from './image'

const SWIPE_TO_CONFIG = { mass: 1, tension: 80, friction: 15 }

const PictureCarousel = ({
  wrapperStyles = {},
  wrapperClassName = '',
  showCloseIcon = false,
  handleCloseClick,
  images,
  spotType,
}) => {
  const { width: viewportWidth } = useViewportSize()

  const [containerRef, inView] = useInView({ threshold: 0 })

  const index = useRef(0)
  const [imagesStyles, setImagesStyles] = useSprings(images.length, (i) => ({
    x: i * viewportWidth,
    scale: 1,
    display: 'block',
  }))

  const bind = useDrag(({ down, last, movement: [mx], direction: [xDir], distance, cancel }) => {
    const isLastPicture = index.current === images.length - 1
    const isFirstPicture = index.current === 0

    // Cancel when swipe to leftmost or rightmost
    if ((mx > 0 && isFirstPicture) || (mx < -0 && isLastPicture)) {
      cancel()
      return
    }

    // Handle touch end and decide whether swiper to next picture
    if (last && distance > viewportWidth / 5) {
      cancel((index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, images.length - 1)))
    }

    // Sync position of pointer and carousel, hide pictures which are out of screen
    setImagesStyles((i) => {
      if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
      const x = (i - index.current) * viewportWidth + (down ? mx : 0)
      return { x, display: 'block', immediate: Boolean(!last) }
    })
  })

  const swiperTo = (targetIndex) => () => {
    index.current = targetIndex
    setImagesStyles((i) => {
      if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
      const x = (i - index.current) * viewportWidth
      return { x, display: 'block', config: SWIPE_TO_CONFIG }
    })
  }

  // Reset carousel index after carousel is out of screen
  useEffect(() => {
    if (!inView) {
      index.current = 0
      setImagesStyles((i) => ({
        x: i * viewportWidth,
        scale: 1,
        display: 'block',
      }))
    }
  }, [inView]) // eslint-disable-line

  const handleClose = () => {
    handleCloseClick && handleCloseClick()
  }

  const getBulletClasses = (isSelected) => {
    return isSelected
      ? 'carousel-pagination__bullet carousel-pagination__bullet--enable'
      : 'carousel-pagination__bullet'
  }

  return (
    <a.div className={wrapperClassName} style={wrapperStyles} ref={containerRef}>
      {imagesStyles.map(({ x, display, scale }, i) => (
        <a.div {...bind()} key={i} style={{ display, x }} className="carousel-image-wrapper">
          <ImageWrapper src={getImagePath(images[i], spotType)} />
        </a.div>
      ))}
      {showCloseIcon && (
        <div className="close-icon-wrapper" onClick={handleClose}>
          <div className="modal-close-icon" />
        </div>
      )}
      <div className="carousel-pagination" style={images.length <= 1 ? { visibility: 'hidden' } : {}}>
        {images.map((value, i) => (
          <div onClick={swiperTo(i)} key={i} className={getBulletClasses(i === index.current)} />
        ))}
      </div>
    </a.div>
  )
}

export default PictureCarousel
