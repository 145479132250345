import { useMainContext } from 'context/main'
import useClickAway from 'hooks/common/useClickAway'

const SpotSwitchModal = () => {
  const {
    send,
    state,
    context: { showSpots, showMinimap },
  } = useMainContext()

  const isModalOpen = state.matches('pano.spotSwitchModal.open')

  const handleCloseModal = () => {
    send('CLOSE_SPOT_SWITCH_MODAL')
  }

  const modalRef = useClickAway(handleCloseModal)

  const transform = isModalOpen ? 'translateY(0)' : 'translateY(-160px)'

  const toggleSpotsVisibility = () => {
    send({ type: 'TOGGLE_SPOT_VISIBILITY', showSpots: !showSpots })
  }

  const toggleMinimapVisibility = () => {
    send({ type: 'TOGGLE_MINIMAP_VISIBILITY', showMinimap: !showMinimap })
  }

  const spotSwitcherClasses = showSpots
    ? 'spot-switch-modal__switcher spot-switch-modal__switcher--active'
    : 'spot-switch-modal__switcher'
  const minimapSwitcherClasses = showMinimap
    ? 'spot-switch-modal__switcher spot-switch-modal__switcher--active'
    : 'spot-switch-modal__switcher'

  const isPanoState = state.matches('pano')

  if (!isPanoState) return null

  return (
    <div className="spot-switch-modal" style={{ transform }} ref={modalRef}>
      <div className="spot-switch-modal__option">
        <div className="spot-switch-modal__text">景點介紹</div>
        <div className={spotSwitcherClasses} onClick={toggleSpotsVisibility}>
          <div className="spot-switch-modal__switcher__selector" />
        </div>
      </div>
      <div className="spot-switch-modal__option">
        <div className="spot-switch-modal__text">小地圖</div>
        <div className={minimapSwitcherClasses} onClick={toggleMinimapVisibility}>
          <div className="spot-switch-modal__switcher__selector" />
        </div>
      </div>
    </div>
  )
}

export default SpotSwitchModal
