export const SCENE_ANGLE_ADJUSTMENT = {
  scene_1f_01: -329.87,
  scene_1f_02: -37.96,
  scene_1f_03: -75.46,
  scene_1f_04: 334.64,
  scene_1f_05: -294.83,
  scene_1f_06: -174.6,
  scene_1f_07: -2.26,
  scene_1f_08: -121.36,
  scene_1f_09: -212.98,
  scene_1f_10: -87.57,
  scene_1f_11: 122.7,
  scene_1f_12: 55.89,
  scene_1f_13: 48.75,
  scene_1f_14: -0.34,
  scene_1f_15: -39.93,
  scene_1f_16: -224.92,
  scene_1f_17: 3,
  scene_1f_18: -174.85,
  scene_1f_19: -110.57,
  scene_1f_20: -182.7,
  scene_1f_21: 278.59,
  scene_1f_22: -358.38,
  scene_1f_23: -14.84,
  scene_1f_24: -249.96,
  scene_1f_25: -180.13,
  scene_1f_26: -186.12,
  scene_1f_27: 180,
  scene_2f_01: 190.06,
  scene_2f_02: 373.16,
  scene_2f_03: 265.08,
  scene_2f_04: 14.61,
  scene_2f_05: -14.93,
  scene_2f_06: 35.18,
  scene_2f_07: -82.83,
  scene_2f_08: 274.19,
  scene_2f_09: 0.34,
  scene_2f_10: 3.04,
  scene_2f_11: 3.85,
  scene_5f_01: 96.88,
  scene_5f_02: -338.13,
  scene_5f_03: -8.09,
  scene_5f_04: 50.72,
  scene_5f_05: -57.35,
  scene_5f_06: -100.41,
  scene_5f_07: -337.3,
  scene_5f_08: -83.69,
  scene_5f_09: -89.18,
  scene_5f_10: -4.76,
  scene_5f_11: -265.29,
  scene_5f_12: -265.45,
  scene_5f_13: -34.31,
  scene_5f_14: -5.64,
  scene_5f_15: -310.4,
  scene_5f_16: -61.69,
  scene_5f_17: -88.12,
  scene_6f_01: -61.62,
  scene_6f_03: 179.07,
  scene_6f_04: -93.44,
  scene_6f_05: -152.09,
  scene_6f_06: -151.14,
}
