import { useEffect, useRef } from 'react'

const DEFAULT_EVENTS = ['mousedown', 'touchstart']

const useClickAway = (onClickAway, excludeAttribute, excludeValue, events = DEFAULT_EVENTS) => {
  const savedCallback = useRef(onClickAway)
  const targetRef = useRef(null)

  useEffect(() => {
    savedCallback.current = onClickAway
  }, [onClickAway])

  useEffect(() => {
    const handler = (event) => {
      const { current: el } = targetRef
      if (el && !el.contains(event.target)) {
        if (excludeAttribute) {
          if (event.target?.[excludeAttribute] !== excludeValue) {
            savedCallback.current(event)
          }
        } else {
          savedCallback.current(event)
        }
      }
    }

    for (const eventName of events) {
      document.addEventListener(eventName, handler)
    }

    return () => {
      for (const eventName of events) {
        document.removeEventListener(eventName, handler)
      }
    }
  }, [events, targetRef, excludeAttribute, excludeValue])

  return targetRef
}

export default useClickAway
