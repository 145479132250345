/* eslint-disable no-restricted-globals */
import { useEffectOnce } from 'react-use'

const useLeaveLineWebview = () => {
  useEffectOnce(() => {
    if (/Line/.test(navigator.userAgent)) {
      const { search } = location
      if (search) {
        location.href = location.href + '&openExternalBrowser=1'
      } else {
        location.href = location.href + '?openExternalBrowser=1'
      }
    }
  })
}

export default useLeaveLineWebview
