import React, { useEffect, useState } from 'react'
import { a } from 'react-spring'

const Image = ({ src, className }) => {
  const [loaded, setLoaded] = useState(false)

  const handleImgLoaded = () => setLoaded(true)
  const resetLoadState = () => setLoaded(false)

  useEffect(() => {
    resetLoadState()
  }, [src])

  return (
    <a.img
      src={src}
      className={className}
      onLoad={handleImgLoaded}
      style={{ opacity: loaded ? 1 : 0 }}
    />
  )
}

export default Image
