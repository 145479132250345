import photoIcon from 'assets/images/map/spot-list/photo.svg'
import tourIcon from 'assets/images/map/spot-list/tour.svg'
import videoIcon from 'assets/images/map/spot-list/video.svg'
import webglIcon from 'assets/images/map/spot-list/webgl.svg'

export const SCENE_SPOTS_SETTING = {
  photo: { withNumber: true, icon: photoIcon, text: '拍照導覽' },
  tour: { withNumber: true, icon: tourIcon, text: '景點導覽' },
  video: { withNumber: true, icon: videoIcon, text: '導覽影片' },
  webgl: { withNumber: true, icon: webglIcon, text: 'VR動畫' },
  // elevator: {withNumber: true, icon: ''},
  // stair: {withNumber: true, icon: ''},
  // wc: {withNumber: true, icon: ''},
  // water: {withNumber: true, icon: ''},
}

export const SCENE_SPOTS_MAP = {
  scene_1f_01: { photo: 1, elevator: 1, stair: 1 },
  scene_1f_02: { tour: 2, stair: 1 },
  scene_1f_03: { elevator: 1, stair: 1 },
  scene_1f_04: { store: 1, elevator: 1, water: 1 },
  scene_1f_05: { store: 1, elevator: 1, wc: 1, water: 1 },
  scene_1f_06: { tour: 2, photo: 1, webgl: 1, elevator: 1, stair: 1, wc: 1 },
  scene_1f_07: { tour: 3, photo: 1, webgl: 1, elevator: 1, stair: 1, wc: 1, water: 1 },
  scene_1f_08: { tour: 1 },
  scene_1f_09: { store: 1, stair: 1 },
  scene_1f_10: { wc: 1 },
  scene_1f_11: { store: 1, stair: 1 },
  scene_1f_12: { photo: 1, store: 1 },
  scene_1f_13: { store: 1 },
  scene_1f_14: { tour: 4, photo: 1 },
  scene_1f_15: { store: 1 },
  scene_1f_16: { store: 1 },
  scene_1f_17: { store: 1, wc: 1 },
  scene_1f_18: { tour: 1, video: 1, wc: 1 },
  scene_1f_19: {},
  scene_1f_20: {},
  scene_1f_21: { tour: 2 },
  scene_1f_22: { tour: 2, stair: 1 },
  scene_1f_23: { tour: 1 },
  scene_1f_24: { store: 1 },
  scene_1f_25: {},
  scene_1f_26: { elevator: 1, stair: 1 },
  scene_1f_27: {},
  scene_2f_01: { tour: 1, video: 1, stair: 1 },
  scene_2f_02: { tour: 1, photo: 1, video: 1, webgl: 1, stair: 1, wc: 1 },
  scene_2f_03: { tour: 1 },
  scene_2f_04: { tour: 2, video: 1, elevator: 1, stair: 1 },
  scene_2f_05: { tour: 2, video: 1, elevator: 1, stair: 1 },
  scene_2f_06: { tour: 2, store: 1, video: 1, wc: 1 },
  scene_2f_07: { tour: 1 },
  scene_2f_08: {},
  scene_2f_09: { tour: 2, store: 1, video: 1, stair: 1 },
  scene_2f_10: { tour: 1, video: 1, stair: 1 },
  scene_2f_11: { tour: 1, elevator: 1, stair: 1, wc: 1 },
  scene_5f_01: {},
  scene_5f_02: {},
  scene_5f_03: { tour: 1 },
  scene_5f_04: { tour: 1, store: 1 },
  scene_5f_05: { photo: 1, store: 1, stair: 1 },
  scene_5f_06: { store: 1, wc: 1 },
  scene_5f_07: { store: 1 },
  scene_5f_08: { store: 1 },
  scene_5f_09: { photo: 1, store: 1 },
  scene_5f_10: { elevator: 1 },
  scene_5f_11: { photo: 1, elevator: 1 },
  scene_5f_12: { photo: 1 },
  scene_5f_13: { tour: 1 },
  scene_5f_14: { wc: 1 },
  scene_5f_15: { photo: 1, store: 1, wc: 1 },
  scene_5f_16: { photo: 1 },
  scene_6f_01: { photo: 1, store: 1 },
  scene_6f_03: { tour: 2 },
  scene_6f_04: {},
  scene_6f_05: { elevator: 1, wc: 1 },
  scene_6f_06: {},
}
