import useViewportSize from 'hooks/common/useViewportSize'
import { useEffect, useRef } from 'react'
import { a, useSpring, to } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import { clamp } from 'lodash'

import { useMainContext } from 'context/main'
import { FLOOR_MAP, OPTIONAL_MARKER_LIST, PUBLIC_FACILITY } from 'config/map'
import { getMapSize, getMapCenterX, getMapMinX } from 'utils/map'
import useDisableIosScroll from 'hooks/common/useDisableIosScroll'

import Loading from './loading'
import vrEntryIcon from 'assets/images/map/entries/mk_vr.svg'

const MAP_AUTO_MOVE_CONFIG = { mass: 1, tension: 74, friction: 15 }

const MAX_IMAGE_HEIGHT = 520
const HEADER_HEIGHT = 44

const SCALE_DISTANCE_RATIO = 600

const MIN_ZOOM_RATIO = -0.5
const MAX_ZOOM_RATIO = 0.2

// To manage gesture events by library
document.addEventListener('gesturestart', (e) => e.preventDefault())
document.addEventListener('gesturechange', (e) => e.preventDefault())

/**
 * TODO: 使用react-lazy-load取代loading動畫
 */
const Map = () => {
  const currentMapX = useRef(0)
  const currentMapZoom = useRef(0)
  const isPinching = useRef(false)

  const { send, state, context } = useMainContext()
  const {
    currentFloor,
    selectedScene,
    nowScene,
    qrcodeScene,
    hasKrpanoEverExecuted,
    urlScene,
    hasSceneParamToggled,
  } = context
  const { mapSrc, vrEntry, naturalImageSize, publicFacilities = {} } = FLOOR_MAP[currentFloor]

  const { width: viewportWidth, height: viewportHeight } = useViewportSize()

  const { actualMapHeight, actualMapWidth } = getMapSize({
    naturalMapHeight: naturalImageSize.h,
    naturalMapWidth: naturalImageSize.w,
    viewportHeight,
    headerHeight: HEADER_HEIGHT,
    maxMapHeight: MAX_IMAGE_HEIGHT,
  })
  const mapCenterX = getMapCenterX({ viewportWidth, actualMapWidth })
  const minMapXValue = getMapMinX({ viewportWidth, actualMapWidth })

  const defaultSelectedScene = qrcodeScene || nowScene
  const nowVrEntry = vrEntry.find(({ sceneName }) => defaultSelectedScene === sceneName)
  const initialX = nowVrEntry?.coords?.[0] * -0.01 * actualMapWidth + viewportWidth / 2
  const [{ x: mapX, zoom, scale }, setMapX] = useSpring(() => ({
    x: nowVrEntry ? clamp(initialX, minMapXValue, 0) : mapCenterX,
    zoom: 0,
    scale: 1,
  }))

  const bind = useGesture(
    {
      onDrag: ({ down, movement: [mx = 0], vxvy: [vx = 0], velocity, direction }) => {
        const currentScale = 1 + zoom.get()
        const newMinX = getMapMinX({ viewportWidth, actualMapWidth, scale: currentScale })
        if (isPinching.current) return

        setMapX({
          x: clamp(mx + (down ? 0 : vx * 300), newMinX, 0) || 0,
          immediate: down,
        })
      },
      onPinch: ({ offset: [b, a], lastOffset, origin, da, movement: [x, y] }) => {
        const newZoom = clamp(
          x / SCALE_DISTANCE_RATIO + currentMapZoom.current,
          MIN_ZOOM_RATIO,
          MAX_ZOOM_RATIO
        )
        if ((newZoom >= MAX_ZOOM_RATIO && x > 0) || (newZoom <= MIN_ZOOM_RATIO && x < 0)) return

        const newScale = newZoom + 1
        const newMinX = getMapMinX({ viewportWidth, actualMapWidth, scale: newScale })
        // TODO: Calculate the real X axios after the zoom changed
        const newX = currentMapX.current - x * newScale

        setMapX({
          zoom: clamp(newZoom, MIN_ZOOM_RATIO, MAX_ZOOM_RATIO),
          x: clamp(newX, newMinX, 0) || 0,
          immediate: true,
        })
      },
      onPinchStart: () => {
        currentMapX.current = mapX.get()
        currentMapZoom.current = zoom.get()
        isPinching.current = true
      },
      onPinchEnd: () => {
        setTimeout(() => {
          isPinching.current = false
        }, 10)
      },
    },
    {
      eventOptions: { passive: false },
      drag: { initial: () => [mapX.get(), 0] },
    }
  )

  useDisableIosScroll()

  const isMapImageIdle = state.matches('map.mapImage.idle')
  const isMapImageLoading = state.matches('map.mapImage.loading')
  const isMapImageLoaded = state.matches('map.mapImage.loaded')

  const handleImageLoaded = () => send('MAP_IMAGE_LOADED')

  // TODO: Rewrite these logic, maybe we can have a `checking` status to manage these action
  useEffect(() => {
    if (isMapImageLoaded && (qrcodeScene || nowScene || urlScene)) {
      if (qrcodeScene) {
        setTimeout(() => {
          send({ type: 'SELECT_VR_ENTRY', scene: qrcodeScene })
          send('CLEAN_QR_CODE_RESULT')

          const nowVrEntry = vrEntry.find(({ sceneName }) => qrcodeScene === sceneName)
          const initialX = nowVrEntry?.coords?.[0] * -0.01 * actualMapWidth + viewportWidth / 2
          setMapX({ x: clamp(initialX, minMapXValue, 0) || 0, config: MAP_AUTO_MOVE_CONFIG })
        })
      } else if (nowScene) {
        const targetScene = nowScene || urlScene
        setTimeout(() => {
          send({ type: 'SELECT_VR_ENTRY', scene: targetScene })
          const nowVrEntry = vrEntry.find(({ sceneName }) => targetScene === sceneName)
          const initialX = nowVrEntry?.coords?.[0] * -0.01 * actualMapWidth + viewportWidth / 2
          setMapX({ x: clamp(initialX, minMapXValue, 0) || 0, config: MAP_AUTO_MOVE_CONFIG })
        })
      } else if (urlScene && !hasSceneParamToggled) {
        const targetScene = nowScene || urlScene
        setTimeout(() => {
          send({ type: 'SELECT_VR_ENTRY', scene: targetScene })
          send({ type: 'SET_SCENE_PARAM_TOGGLED' })
          const nowVrEntry = vrEntry.find(({ sceneName }) => targetScene === sceneName)
          const initialX = nowVrEntry?.coords?.[0] * -0.01 * actualMapWidth + viewportWidth / 2
          setMapX({ x: clamp(initialX, minMapXValue, 0) || 0, config: MAP_AUTO_MOVE_CONFIG })
        })
      }
    }
  }, [qrcodeScene, nowScene, isMapImageLoaded, urlScene]) // eslint-disable-line

  useEffect(() => {
    if (!(qrcodeScene || nowScene || urlScene)) {
      setMapX({ x: mapCenterX || 0, immediate: true, zoom: 0 })
    }
  }, [mapSrc]) // eslint-disable-line

  const otherPublicFacilities = publicFacilities?.others

  return (
    <>
      <Loading isLoading={isMapImageLoading || isMapImageIdle} />
      <a.div className="map-container" style={{ opacity: isMapImageLoading ? 0 : 1 }} {...bind()}>
        <a.div
          className="map-container__map-images"
          style={{
            height: actualMapHeight,
            x: mapX,
            top: `calc((100% - ${actualMapHeight}px) / 2)`,
            scale: to([scale, zoom], (s, z) => s + z),
          }}
        >
          {!isMapImageIdle && <img alt="map" src={mapSrc} onLoad={handleImageLoaded} />}
          <a.div
            className="icon-group-container"
            style={{
              height: actualMapHeight,
              width: actualMapWidth,
              display: to([scale, zoom], (s, z) => {
                if (s + z < 0.7 || !isMapImageLoaded) return 'none'
                return 'block'
              }),
            }}
          >
            {OPTIONAL_MARKER_LIST.map((iconName) => {
              const iconGroup = publicFacilities?.[iconName]
              if (!iconGroup || state.matches(`map.${iconName}.disable`)) return null

              return iconGroup.map(({ coords: [x, y], id, icon }) => {
                const handleClick = () => {
                  send({ type: 'OPEN_PUBLIC_FACILITY_MODAL', publicFacilityId: id })
                }

                const style = {
                  display: to([scale, zoom], (s, z) => (s + z < 0.9 ? 'none' : 'block')),
                  left: `${x}%`,
                  bottom: `${y}%`,
                  color: 'black',
                }

                const iconSrc = icon || PUBLIC_FACILITY?.[iconName].icon

                return (
                  <div className="vr-entry-container" style={style} key={id} onClick={handleClick}>
                    <img src={iconSrc} alt="public-facility" />
                  </div>
                )
              })
            })}
            {otherPublicFacilities &&
              otherPublicFacilities.map(({ coords: [x, y], id, icon }) => {
                const handleClick = () => {
                  send({ type: 'OPEN_PUBLIC_FACILITY_MODAL', publicFacilityId: id })
                }

                const style = {
                  display: to([scale, zoom], (s, z) => (s + z < 0.9 ? 'none' : 'block')),
                  left: `${x}%`,
                  bottom: `${y}%`,
                  color: 'black',
                }

                return (
                  <div className="vr-entry-container" style={style} key={id} onClick={handleClick}>
                    <img src={icon} alt="public-facility" />
                  </div>
                )
              })}
            {vrEntry.map(({ coords: [x, y], sceneName, icon }) => {
              const handleClick = () => {
                if (sceneName !== selectedScene) {
                  send({ type: 'SELECT_VR_ENTRY', scene: sceneName })
                } else {
                  // Note. We have duplicate scene name but for only one pano,
                  //       so the duplicated vr entry will have a `-duplicated` postfix
                  const targetSceneName = sceneName.replace('-duplicated', '')
                  hasKrpanoEverExecuted
                    ? send({ type: 'BACK_TO_COMPLETE_PANO_PAGE', scene: targetSceneName })
                    : send({ type: 'GO_TO_PANO_PAGE', hash: targetSceneName })
                }
              }

              const containerClasses =
                selectedScene === sceneName
                  ? 'vr-entry-container vr-entry-container--selected'
                  : 'vr-entry-container'

              return (
                <div
                  className={containerClasses}
                  style={{ left: `${x}%`, bottom: `${y}%`, color: 'black' }}
                  key={sceneName}
                  onClick={handleClick}
                >
                  <img src={icon || vrEntryIcon} alt="vr-entry" onClick={handleClick} />
                </div>
              )
            })}
          </a.div>
        </a.div>
      </a.div>
    </>
  )
}

export default Map
