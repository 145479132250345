import React, { useEffect } from 'react'
import { useSpring, a } from 'react-spring'
import { SPOT_REVIEW } from 'config/krpano'
import useViewportSize from 'hooks/common/useViewportSize'
import { useMainContext } from 'context/main'

import Carousel from 'components/pano/common/carousel'

const PREVIEW_OPEN_CONFIG = { mass: 1, tension: 80, friction: 15 }

const PREVIEW_CLOSE_CONFIG = { mass: 1, tension: 90, friction: 14 }

// photoDir = 'H' or 'V'
const PhotoSpot = () => {
  const { height: viewportHeight, width: viewportWidth } = useViewportSize()
  const horizontalPhotoHeight = (viewportWidth * 9) / 16

  const {
    send,
    state,
    context: { spotName },
  } = useMainContext()

  const isPanoState = state.matches('pano')
  const isModalOpenState = state.matches('pano.photoSpotModal.open')
  const shoulsOpenModal = isModalOpenState && Boolean(spotName)

  const [{ y: modealY }, setModalY] = useSpring(() => ({
    y: viewportHeight,
  }))

  const open = () => {
    setModalY({ y: 0, config: PREVIEW_OPEN_CONFIG })
  }

  const close = (velocity = 0) => {
    setModalY({ y: viewportHeight, config: PREVIEW_CLOSE_CONFIG })
    send({ type: 'CLOSE_PHOTO_SPOT_MODAL' })
  }

  useEffect(() => {
    if (shoulsOpenModal) {
      open()
    }
  }, [shoulsOpenModal]) // eslint-disable-line

  const display = modealY.to((py) => (py < viewportHeight && isPanoState ? 'block' : 'none'))

  const { images = [], photoDir = 'v' } = SPOT_REVIEW[spotName] ?? {}

  const wrapperClasses =
    photoDir === 'v'
      ? 'photo-spot-preview photo-spot-preview--h'
      : 'photo-spot-preview photo-spot-preview--v'

  const hintStyle = photoDir === 'v' ? {} : { height: viewportHeight - horizontalPhotoHeight }

  const carouselStyle = photoDir === 'v' ? { width: viewportWidth } : { height: horizontalPhotoHeight }

  return (
    <a.div className={wrapperClasses} style={{ y: modealY, height: viewportHeight, display }}>
      <div className="close-icon-wrapper">
        <div className="modal-close-icon" onClick={close} />
      </div>
      <div className="photo-spot-preview__hint" style={hintStyle}>
        <div className="photo-spot-preview__hint__title">
          喀嚓！
          <br />
          換你拍一張吧。
        </div>
        <div className="photo-spot-preview__hint__sub-title">還在等什麼！？打開相機記錄這一刻吧。</div>
      </div>
      <Carousel
        wrapperStyles={carouselStyle}
        wrapperClassName="photo-spot-preview__wrapper"
        showCloseIcon={false}
        images={images}
        spotType="photo"
      />
    </a.div>
  )
}

export default PhotoSpot
