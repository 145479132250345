import { useState, useEffect } from 'react'
import { useMainContext } from 'context/main'

import outlookImg from 'assets/images/home/outlook.png'
import logoImg from 'assets/images/home/ntt-logo.png'

const Home = () => {
  const {
    send,
    context: { currentFloor },
  } = useMainContext()
  const [isFadeOut, setIsFadeOut] = useState(false)

  const [isFontLoaded, setIsFontLoaded] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const goToMapPage = () => {
    if (!isFadeOut) {
      setIsFadeOut(true)
      setTimeout(() => {
        send({ type: 'GO_TO_NEW_MAP_PAGE', currentFloor })
      }, 1500)
    }
  }

  const setFontLoaded = () => setIsFontLoaded(true)
  const setImageLoaded = () => setIsImageLoaded(true)
  const setPageLoaded = () => setIsLoading(false)

  useEffect(() => {
    document.fonts.ready.then(() => setFontLoaded()).catch(() => setFontLoaded())
  }, [])

  return (
    <>
      {isLoading && (
        <div
          className={
            !isFontLoaded || !isImageLoaded
              ? 'home-loading-container'
              : 'home-loading-container home-loading-container--fade-out'
          }
          onAnimationEnd={setPageLoaded}
        >
          <div className="loading-animation" />
        </div>
      )}
      <div className="home-page disable-select" style={{ opacity: isFadeOut ? 0 : 1 }}>
        <img alt="logo" src={logoImg} className="home-page__logo" />
        <div className="home-page__outlook" onClick={goToMapPage}>
          <img
            alt="out-look"
            src={outlookImg}
            onLoad={setImageLoaded}
            style={{ transform: isFadeOut ? 'scale(2)' : 'scale(1)' }}
          />
        </div>
        <div className="home-page__title">
          打破時間與空間，
          <br />
          沉浸其中。
        </div>
        <div className="home-page__sub-title">接下來將要帶您透過VR與3D技術體驗歌劇院的更多可能性！</div>
        <div className="home-page__link-button" onClick={goToMapPage}>
          開始導覽
        </div>
      </div>
    </>
  )
}

export default Home
