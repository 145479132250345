import { send } from 'xstate'
import { PAGE_STATUS, MARKER_STATUS, KRPANO_STATUS } from 'constants/xstate'

const mapStates = {
  id: 'map',
  type: 'parallel',
  on: {
    DISABLE_ALL_MARKER: {
      target: [
        `.${MARKER_STATUS.STAIR}.disable`,
        `.${MARKER_STATUS.WC}.disable`,
        `.${MARKER_STATUS.ELEVATOR}.disable`,
        `.${MARKER_STATUS.WATER}.disable`,
      ],
      internal: true,
    },
    CLEAN_QR_CODE_RESULT: { actions: 'cleanQrcodeResult' },
    SET_AND_CLOSE_QRCODE_MODAL: [
      {
        cond: 'isSameFloor',
        actions: ['setQrcodeScene', 'changeMapUrl', 'setSceneParamNotToggled'],
        target: '.qrModal.close',
      },
      {
        actions: ['setQrcodeScene', 'setCurrentFloor', 'changeMapUrl', 'setSceneParamNotToggled'],
        target: ['.qrModal.close', '.mapImage.idle'],
      },
    ],
    SWITCH_FLOOR: {
      actions: ['setCurrentFloor', 'changeMapUrl', 'cleanSelectedScene', 'cleanNowScene'],
      target: '.mapImage.idle',
    },
    SET_SCENE_PARAM_TOGGLED: { actions: 'setSceneParamToggled' },
    RESET_SCENE_PARAM_TOGGLE: { actions: 'setSceneParamNotToggled' },
  },
  exit: ['cleanSelectedScene', 'cleanNowScene'],
  states: {
    hist: {
      type: 'history',
      history: 'deep',
    },
    mapImage: {
      initial: 'idle',
      states: {
        idle: { after: { 300: 'loading' } },
        loading: {
          on: { MAP_IMAGE_LOADED: 'loaded' },
        },
        loaded: {
          entry: 'setSelectedSceneFromUrl',
          on: {
            CHANGE_MAP_IMAGE_SRC: 'idle',
            SELECT_VR_ENTRY: { actions: 'setSelectedScene' },
            CANCEL_VR_ENTRY: { target: 'loaded', actions: 'cleanSelectedScene' },
          },
          exit: ['cleanSelectedScene'],
        },
      },
    },
    markersMenu: {
      initial: 'close',
      states: {
        close: {
          on: { OPEN_MARKERS_MENU: 'open' },
        },
        open: {
          on: {
            CLOSE_MARKERS_MENU: { target: 'close' },
            CANCEL_MARKERS_MENU: [
              { target: 'close', cond: 'hasNoEnableMarker' },
              { target: 'cancelling' },
            ],
          },
        },
        cancelling: {
          entry: send('DISABLE_ALL_MARKER'),
          after: {
            400: 'close',
          },
        },
      },
    },
    qrModal: {
      initial: 'close',
      states: {
        close: {
          on: { OPEN_QRCODE_MODAL: 'open' },
        },
        open: {
          on: {
            CLOSE_QRCODE_MODAL: 'close',
            OPEN_QRCODE_CAMERA: 'openCamera',
          },
        },
        openCamera: {
          on: { CLOSE_QRCODE_MODAL: 'close' },
        },
      },
    },
    publicFacilityModal: {
      initial: 'close',
      states: {
        close: {
          on: { OPEN_PUBLIC_FACILITY_MODAL: { target: 'open', actions: 'setPublicFacilityId' } },
        },
        open: {
          on: { CLOSE_PUBLIC_FACILITY_MODAL: 'close' },
        },
      },
    },
    [MARKER_STATUS.WATER]: {
      initial: 'disable',
      states: {
        disable: {
          on: { TOGGLE_WATER_MARKER: 'enable' },
        },
        enable: {
          on: { TOGGLE_WATER_MARKER: 'disable' },
        },
      },
    },
    [MARKER_STATUS.WC]: {
      initial: 'disable',
      states: {
        disable: {
          on: { TOGGLE_WC_MARKER: 'enable' },
        },
        enable: {
          on: { TOGGLE_WC_MARKER: 'disable' },
        },
      },
    },
    [MARKER_STATUS.STAIR]: {
      initial: 'disable',
      states: {
        disable: {
          on: { TOGGLE_STAIR_MARKER: 'enable' },
        },
        enable: {
          on: { TOGGLE_STAIR_MARKER: 'disable' },
        },
      },
    },
    [MARKER_STATUS.ELEVATOR]: {
      initial: 'disable',
      states: {
        disable: {
          on: { TOGGLE_ELEVATOR_MARKER: 'enable' },
        },
        enable: {
          on: { TOGGLE_ELEVATOR_MARKER: 'disable' },
        },
      },
    },
  },
}

const panoState = {
  id: 'pano',
  type: 'parallel',
  exit: ['setNowScene', 'enableShowSpot', 'stopAutoplayVideo'],
  on: {
    OPEN_VIDEO_PLAYER: { actions: 'setVideoPlayerOpenWithSrc' },
    CLOSE_VIDEO_PLAYER: { actions: 'closeVideoPlayer' },
    OPEN_AR_IFRAME: { actions: 'setARIframeOpenWithSrc' },
    CLOSE_AR_IFRAME: { actions: 'closeARIframe' },
  },
  states: {
    hist: {
      type: 'history',
      history: 'deep',
    },
    krpano: {
      initial: KRPANO_STATUS.IDLE,
      states: {
        [KRPANO_STATUS.IDLE]: {
          entry: 'initVisitedLocalStorageHistory',
          on: { ON_KRPANO_LOADED: KRPANO_STATUS.KRPANO_LOADED },
        },
        [KRPANO_STATUS.KRPANO_LOADED]: {
          on: { ON_NEW_SCENE: KRPANO_STATUS.NEW_SCENE },
          entry: [
            'setKrpanoInterface',
            'setCurrentScene',
            'setKrpanoExecuted',
            'updateVisitedLocalStorageHistory',
            'initVisitedPanoList',
            'setVisitedPanoList',
          ],
        },
        [KRPANO_STATUS.NEW_SCENE]: {
          entry: [
            'setCurrentScene',
            'changePanoSceneUrl',
            'updateVisitedLocalStorageHistory',
            'setVisitedPanoList',
          ],
          on: { ON_NEW_PANO: KRPANO_STATUS.NEW_PANO },
        },
        [KRPANO_STATUS.NEW_PANO]: {
          entry: ['markVisitedPanos', 'toggleSpotVisibility'],
          on: { ON_PREVIEW_COMPLETE: KRPANO_STATUS.PREVIEW_COMPLETE },
        },
        [KRPANO_STATUS.PREVIEW_COMPLETE]: {
          entry: ['handleAutoplayVideo'],
          on: { ON_LOAD_COMPLETE: KRPANO_STATUS.LOAD_COMPLETE },
        },
        [KRPANO_STATUS.LOAD_COMPLETE]: {
          entry: ['loadScene'],
          exit: ['setPreviousScene'],
          on: { ON_NEW_SCENE: KRPANO_STATUS.NEW_SCENE },
        },
      },
    },
    spotSwitchModal: {
      initial: 'close',
      states: {
        close: {
          on: { OPEN_SPOT_SWITCH_MODAL: 'open' },
        },
        open: {
          on: {
            CLOSE_SPOT_SWITCH_MODAL: 'close',
            TOGGLE_SPOT_VISIBILITY: { actions: ['toggleSpotVisibility', 'setShowSpots'] },
            TOGGLE_MINIMAP_VISIBILITY: { actions: ['setShowMinimap'] },
          },
        },
      },
    },
    tourSpotModal: {
      initial: 'close',
      states: {
        close: {
          on: { OPEN_TOUR_SPOT_MODAL: { target: 'open', actions: 'setSpotName' } },
        },
        open: {
          on: { CLOSE_TOUR_SPOT_MODAL: 'close' },
        },
      },
    },
    photoSpotModal: {
      initial: 'close',
      states: {
        close: {
          on: { OPEN_PHOTO_SPOT_MODAL: { target: 'open', actions: 'setSpotName' } },
        },
        open: {
          on: { CLOSE_PHOTO_SPOT_MODAL: 'close' },
        },
      },
    },
  },
}

const pageState = {
  initial: 'startup',
  on: {
    GO_TO_OVERVIEW_PAGE: `.${PAGE_STATUS.OVERVIEW}`,
    GO_TO_NEW_MAP_PAGE: `.${PAGE_STATUS.MAP}`,
    BACK_TO_MAP_PAGE: `.${PAGE_STATUS.MAP}.hist`,
    GO_TO_PANO_PAGE: `.${PAGE_STATUS.PANO}.hist`,
    BACK_TO_COMPLETE_PANO_PAGE: `.${PAGE_STATUS.PANO}.krpano.${KRPANO_STATUS.LOAD_COMPLETE}`,
  },
  entry: ['initVisitedLocalStorageHistory', 'initVisitedPanoList'],
  states: {
    startup: {
      entry: 'initialGA',
      on: {
        '': [
          { target: PAGE_STATUS.OVERVIEW, cond: 'isOverviewRoute' },
          { target: PAGE_STATUS.MAP, cond: 'isMapRoute' },
          { target: PAGE_STATUS.PANO, cond: 'isPanoRoute' },
          { target: PAGE_STATUS.HOME },
        ],
      },
    },
    [PAGE_STATUS.HOME]: {
      entry: 'handlePageEntry',
    },
    [PAGE_STATUS.OVERVIEW]: {
      entry: 'handlePageEntry',
    },
    [PAGE_STATUS.MAP]: {
      entry: ['handlePageEntry', 'setCurrentFloor'],
      ...mapStates,
    },
    [PAGE_STATUS.PANO]: {
      entry: ['handlePageEntry', 'setKrpanoExecuted'],
      ...panoState,
      // entry: Set a flag to tell whether krpano loaded
      // exit: Handle something when leave krpano, eg. pause video
    },
  },
}

export default pageState
