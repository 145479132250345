import { Machine } from 'xstate'
import states from './fsm'
import guards from './guards'
import actions from './actions'
import { FLOOR_PATH } from 'constants/path'

const initialContext = {
  // This is a workaround to prevent krapno starting again and reduce the waiting time during page changing
  hasKrpanoEverExecuted: false,

  currentFloor: FLOOR_PATH.F1,

  qrcodePano: null,
  qrcodeScene: null,

  showSpots: true,
  showMinimap: true,
  spotName: '',
  visitedPanoList: [],

  publicFacilityId: '',

  urlScene: null,

  nowScene: null, // The value will be the scene name before leave pano
  currentScene: null, // Scene name in pano, will be changed every time user go to different scene
  previousScene: null, // Scene name in pano, will be setted when new pano
  selectedScene: null, // Selected scene name of VR entry in map

  // This is the temperature workaround for checking if the qrcode scene or url scene has been toggled
  hasSceneParamToggled: false,

  isVideoPlayerOpen: false,
  videoSrc: '',

  isArIframeOpen: false,
  arSrc: '',

  // Krpano javascript interface
  callKrpano: null,
  getKrpano: null,
  setKrpano: null,
}

export const MainMachine = Machine(
  {
    id: 'NttMachine',
    context: initialContext,
    ...states,
  },
  {
    actions,
    guards,
  }
)
