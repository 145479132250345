import { useMainContext } from 'context/main'
import useClickAway from 'hooks/common/useClickAway'
import { usePrevious } from 'react-use'
import { toPairs } from 'lodash'

import { SCENE_SPOTS_SETTING, SCENE_SPOTS_MAP } from 'config/sceneSpot'

import panoIcon from 'assets/images/map/360-black-18dp.svg'

const getHintText = (nowScene, prevSelectedScene, selectedScene, visitedPanoList) => {
  const visibleSelectedScene = selectedScene || prevSelectedScene
  if (nowScene === visibleSelectedScene) {
    return '目前所在位置'
  }

  if (visitedPanoList.some((scene) => scene === visibleSelectedScene)) {
    return '您曾探索過這個區域'
  }

  return '這個區域您不曾探索過'
}

const SceneReviewModal = () => {
  const {
    send,
    context: { selectedScene, hasKrpanoEverExecuted, nowScene, visitedPanoList },
  } = useMainContext()
  const prevSelectedScene = usePrevious(selectedScene)

  const isModalClose = !Boolean(selectedScene)

  const handleCloseModal = () => {
    if (selectedScene) {
      send('CANCEL_VR_ENTRY')
    }
  }
  const handleConfirmBtn = () => {
    if (!selectedScene) return

    hasKrpanoEverExecuted
      ? send({ type: 'BACK_TO_COMPLETE_PANO_PAGE', scene: selectedScene })
      : send({ type: 'GO_TO_PANO_PAGE', hash: selectedScene })
  }

  const modalRef = useClickAway(handleCloseModal, 'alt', 'vr-entry')

  const transform = isModalClose ? 'translateY(-100px)' : 'translateY(0)'

  return (
    <div
      className="scene-review-modal disable-select"
      style={{ transform }}
      ref={modalRef}
      onClick={handleConfirmBtn}
    >
      <div className="scene-review-modal__check">
        <div className="scene-review-modal__check__hint">
          {getHintText(nowScene, prevSelectedScene, selectedScene, visitedPanoList)}
        </div>
        <div className="scene-review-modal__check__enter">
          <img src={panoIcon} alt="pano" />
          探索
        </div>
      </div>
      <div className="scene-review-modal__list">
        {selectedScene &&
          toPairs(SCENE_SPOTS_MAP?.[selectedScene] ?? []).map(([spotKey, count]) => {
            if (!SCENE_SPOTS_SETTING[spotKey]) return null

            return (
              <div className="scene-review-modal__list__spot-wrapper" key={spotKey}>
                <div className="spot-thumb">
                  <img src={SCENE_SPOTS_SETTING[spotKey].icon} alt={spotKey} />
                </div>
                <div className="spot-text">{SCENE_SPOTS_SETTING[spotKey].text}</div>
                <div className="spot-count">{count}</div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default SceneReviewModal
