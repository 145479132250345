import { useEffect, useState } from 'react'

export const isClient = typeof window === 'object'

const useViewportSize = () => {
  const [size, setSize] = useState({
    width: isClient ? window.innerWidth : 0,
    height: isClient ? window.innerHeight : 0,
  })

  useEffect(() => {
    const updateSize = () => {
      setSize({
        width: isClient ? window.innerWidth : 0,
        height: isClient ? window.innerHeight : 0,
      })
    }

    window.addEventListener('resize', updateSize)

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  return {
    height: size.height,
    width: size.width,
  }
}

export default useViewportSize
