import { useState } from 'react'

import Header from './header'
import Selector from './selector'
import Map from './map'
import QrcodeModal from './qrcodeModal'
import SceneReviewModal from './sceneReviewModal'
import PublicFacilityModal from './publicFacilityModal'

const MapPage = () => {
  const [isFadeOut] = useState(false)

  return (
    <div className="map-page" style={{ transition: 'opacity 0.8s', opacity: isFadeOut ? 0 : 1 }}>
      <Header />
      <Map />
      <Selector />
      <QrcodeModal />
      <PublicFacilityModal />
      <SceneReviewModal />
    </div>
  )
}

export default MapPage
