import { useEffect } from 'react'

const useDisableIosScroll = () => {
  useEffect(() => {
    document.ontouchmove = function(e) {
      e.preventDefault()
    }

    return () => {
      document.ontouchmove = function(e) {
        return true
      }
    }
  })
}

export default useDisableIosScroll
