import { useEffect } from 'react'
import { MARKER_MAP } from 'config/map'
import { useSpring, a } from 'react-spring'
import { keys } from 'lodash'
import { useMainContext } from 'context/main'
import useClickAway from 'hooks/common/useClickAway'

const BOX_HEIGHT = 200

const Selector = () => {
  const { send, state } = useMainContext()
  const [{ y }, setY] = useSpring(() => ({ y: BOX_HEIGHT }))

  const menuState = state.value?.map?.markersMenu
  const openModal = () => setY({ y: 0 })
  const closeModal = () => setY({ y: BOX_HEIGHT })

  useEffect(() => {
    menuState === 'close' ? closeModal() : openModal()
  }, [menuState]) // eslint-disable-line

  const handleConfirm = () => send('CLOSE_MARKERS_MENU')
  const handleCancel = () => send('CANCEL_MARKERS_MENU')

  const modalRef = useClickAway(handleConfirm)

  return (
    <a.div className="marker-selector disable-select" style={{ y }} ref={modalRef}>
      <div className="marker-selector__title">在找什麼呢？</div>
      <div className="marker-selector__options">
        {keys(MARKER_MAP).map((key) => {
          const { icon, text, event } = MARKER_MAP[key]
          const isEnable = (state.value?.map?.[key] ?? 'disable') === 'enable'
          const handleClick = () => send(event)
          const classes = isEnable
            ? 'marker-icon-wrapper marker-icon-wrapper--enable'
            : 'marker-icon-wrapper'

          return (
            <div className={classes} key={text} onClick={handleClick}>
              <img alt="icon" src={icon} />
              <div>{text}</div>
            </div>
          )
        })}
      </div>
      <div className="marker-selector__footer">
        <div onClick={handleCancel} className="marker-selector__footer__cancel">
          取消選取
        </div>
        <div onClick={handleConfirm} className="marker-selector__footer__confirm">
          確認
        </div>
      </div>
    </a.div>
  )
}

export default Selector
