import { useState, useEffect } from 'react'
import { useMainContext } from 'context/main'
import { toPairs } from 'lodash'

import { OVERVIEW_FLOOR_MAP } from 'config/overview'

import goToIcon from 'assets/images/overview/arrow_back_ios-black-18dp.svg'

const HIDE_STYLE = { opacity: 0, transform: 'translateY(-200px)' }
const SHOW_STYLE = { opacity: 1, transform: 'translateY(0)' }

const Overview = () => {
  const [isFadeIn, setIsFadeIn] = useState(false)
  const {
    send,
    context: { currentFloor },
  } = useMainContext()

  const handleGoMap = (newFloor) => {
    setIsFadeIn(false)
    if (newFloor === currentFloor) {
      setTimeout(() => send({ type: 'BACK_TO_MAP_PAGE', currentFloor }), 1000)
    } else {
      setTimeout(() => send({ type: 'GO_TO_NEW_MAP_PAGE', currentFloor: newFloor }), 1000)
    }
  }

  useEffect(() => {
    var element = document.getElementById('scroll')
    element.scrollIntoView()
    setTimeout(() => setIsFadeIn(true))
  }, [])

  return (
    <>
      <div className="overview-page-top-mask" style={{ opacity: isFadeIn ? 1 : 0 }}>
        <div className="close-icon-wrapper" onClick={() => handleGoMap(currentFloor)}>
          <div className="modal-close-icon" />
        </div>
      </div>
      <div className="overview-page">
        {toPairs(OVERVIEW_FLOOR_MAP).map(([key, { mapSrc, spots, text }]) => {
          return (
            <div
              className="floor-card"
              style={isFadeIn ? SHOW_STYLE : HIDE_STYLE}
              onClick={() => handleGoMap(key)}
            >
              <img src={mapSrc} alt="map-thumb" className="floor-card__image" />
              <div className="floor-card__spots">
                {spots.map((spot) => (
                  <div>{spot}</div>
                ))}
              </div>
              <div className="floor-card__footer">
                <div className="floor-card__footer__text">{text}</div>
                <img className="floor-card__footer__icon" alt="go-to" src={goToIcon} />
              </div>
            </div>
          )
        })}
        <div id="scroll"></div>
      </div>
    </>
  )
}

export default Overview
