import iconImg from 'assets/images/map/icon-qrcode.svg'

const ScanBtn = ({ onClick }) => {
  return (
    <div className="scanner-icon" onClick={onClick}>
      <img src={iconImg} alt="scan" />
    </div>
  )
}

export default ScanBtn
