import { useEffect } from 'react'
import { useMainContext } from 'context/main'
import useKrpano from 'hooks/useKrpano'
import { isDevMode } from 'constants/base'
import { getSpotText } from 'utils/krpano'
import { KRPANO_NULL, PANO_SPOT_TYPE } from 'constants/base'
import { useSearchParam, useCopyToClipboard } from 'react-use'
import { getFloorFromScene } from 'utils/krpano'

import { CUSTOM_KRPANO_EMBEDDING_PARAMS } from 'constants/base'
import Iframe from 'react-iframe'

import PanoController from './controller'
import Loading from './loading'
import TourSpot from './tourSpot'
import PhotoSpot from './photoSpot'
import Minimap from './minimap'
import SpotSwitchModal from './controller/spotSwitchModal'
import VideoPlayer from 'components/common/videoPlayer'

const HIDE_STYLE = { zIndex: -1, opacity: 0 }
const SHOW_STYLE = { zIndex: 2, opacity: 1 }

const Pano = () => {
  const {
    send,
    state,
    context: { isArIframeOpen, arSrc },
  } = useMainContext()

  const enableCopy = useSearchParam('copy')
  const [, copyToClipboard] = useCopyToClipboard()

  const onNewScene = (scene = '') => {
    if(!scene) return
    const floorKey = `/${getFloorFromScene(scene)}`
    send({ type: 'ON_NEW_SCENE', scene, currentFloor: floorKey })
  }
  const onNewPano = () => send('ON_NEW_PANO')
  const onPreviewComplete = () => send('ON_PREVIEW_COMPLETE')
  const onLoadComplete = () => send('ON_LOAD_COMPLETE')

  const openSpotModal = (name = '', type) => {
    const isEmptyType = type === KRPANO_NULL || !Boolean(type)
    if (isEmptyType || type === PANO_SPOT_TYPE.TOUR) {
      send({ type: 'OPEN_TOUR_SPOT_MODAL', name })
    } else {
      send({ type: 'OPEN_PHOTO_SPOT_MODAL', name })
    }
  }

  const logKrpanoAndCopy = (str = '') => {
    if (enableCopy) {
      copyToClipboard(str)
      console.log(str)
    }
  }

  const setVideoSource = (videoSrc = '') => send({ type: 'OPEN_VIDEO_PLAYER', videoSrc })
  const openAR = (arSrc = '') => send({ type: 'OPEN_AR_IFRAME', arSrc })
  const handleIframeClose = () => send('CLOSE_AR_IFRAME')

  const {
    containerRef,
    krpanoState: { isLoaded },
    setKrpano,
    callKrpano,
    getKrpano,
  } = useKrpano({
    embeddingParams: CUSTOM_KRPANO_EMBEDDING_PARAMS,
    globalFunctions: {
      onNewScene,
      onNewPano,
      onPreviewComplete,
      onLoadComplete,
      getSpotText,
      openSpotModal,
      setVideoSource,
      openAR,
      logKrpanoAndCopy,
    },
    height: '100%',
    width: '100%',
  })

  // Initial krpano interface with xstate
  useEffect(() => {
    if (isLoaded) {
      const scene = getKrpano('xml.scene')
      send({ type: 'ON_KRPANO_LOADED', scene, callKrpano, getKrpano, setKrpano })
      setKrpano('showerrors', isDevMode)
    }
  }, [isLoaded]) // eslint-disable-line

  const isPanoState = state.matches('pano')

  const isKrpanoIniting = state.matches('pano.krpano.idle')

  return (
    <>
      <div ref={containerRef} style={isPanoState ? SHOW_STYLE : HIDE_STYLE} />
      {isKrpanoIniting && <Loading />}
      <PanoController />
      <TourSpot />
      <PhotoSpot />
      <SpotSwitchModal />
      <VideoPlayer />
      <Minimap />
      {isArIframeOpen && <Iframe url={arSrc} className="webgl-iframe" display="initial" />}
      {isArIframeOpen && (
        <div className="close-icon-wrapper ar-iframe" onClick={handleIframeClose}>
          <div className="modal-close-icon" />
        </div>
      )}
    </>
  )
}

export default Pano
