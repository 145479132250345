import { useEffect, useState } from 'react'
import { a } from 'react-spring'
import { round, throttle } from 'lodash'
import { getFloorFromScene } from 'utils/krpano'

import directionIcon from 'assets/images/pano/minimap/direction.svg'
import dumpIcon from 'assets/images/pano/minimap/direction-no-arrow.svg'

import { useMainContext } from 'context/main'
import { FLOOR_MAP } from 'config/map'
import { SCENE_ANGLE_ADJUSTMENT } from 'config/minimap'

const MINIMAP_BOX = {
  HEIGHT: 110,
  WIDTH: 110,
}

const ACTUAL_MAP_HEIGHT = 220

const X_AXIOS_TUNE = 1.5

const Minimap = () => {
  const [angle, setAngle] = useState(0)
  const [isMoving, setIsMoving] = useState(false)
  const { state, context } = useMainContext()
  const { currentScene, getKrpano, showMinimap, currentFloor } = context
  const [adjustmentAngle, setAdjustmentAngle] = useState(0)
  const krpanoFloorKey = `/${getFloorFromScene(currentScene)}`

  const isPanoState = state.matches('pano')
  const isIdleState = state.matches('pano.krpano.idle')

  const { minimapSrc, vrEntry, naturalImageSize } = FLOOR_MAP?.[krpanoFloorKey] ?? {}

  const getCurrentHlookat = throttle(
    () => {
      setAngle(Number(getKrpano('view.hlookat')) % 360)
    },
    40,
    { leading: true, trailing: true }
  )

  const handleSceneChange = () => {
    setAdjustmentAngle(SCENE_ANGLE_ADJUSTMENT?.[currentScene] ?? 0)
    getCurrentHlookat()
  }

  const handlePanoMoving = () => setIsMoving(true)
  const handlePanoMovingEnd = () => {
    handleSceneChange()
    setIsMoving(false)
  }

  useEffect(() => {
    handlePanoMoving()
  }, [currentScene, currentFloor])

  useEffect(() => {
    if (!isPanoState) return

    if (getKrpano) {
      handleSceneChange()
    }

    document.addEventListener('touchmove', getCurrentHlookat)

    return () => {
      document.removeEventListener('touchmove', getCurrentHlookat)
    }
  }, [getKrpano, isPanoState]) // eslint-disable-line

  if (!currentScene || !isPanoState || isIdleState) return null
  const currentSceneInfo = vrEntry.find(({ sceneName }) => currentScene === sceneName)

  const [vrEntryX, vrEntryY] = currentSceneInfo?.coords

  const imgScaleRatio = naturalImageSize.h / ACTUAL_MAP_HEIGHT

  const actualMapHeight = ACTUAL_MAP_HEIGHT
  const actualMapWidth = naturalImageSize.w / imgScaleRatio

  const translteX = `${(round((vrEntryX * actualMapWidth) / 100, 2) - MINIMAP_BOX.WIDTH / 2) * -1}px`
  const translteY = `${(round(((100 - vrEntryY - X_AXIOS_TUNE) * actualMapHeight) / 100, 2) -
    MINIMAP_BOX.WIDTH / 2) *
    -1}px`
  const mapStyle = {
    height: ACTUAL_MAP_HEIGHT,
    width: 'auto',
    transform: `translate(${translteX}, ${translteY})`,
  }

  const rotateStyle = `rotate(${angle - adjustmentAngle}deg)`

  if (!showMinimap) return null

  return (
    <a.div
      className="minimap-container disable-select"
      style={{ height: MINIMAP_BOX.HEIGHT, width: MINIMAP_BOX.WIDTH }}
    >
      <a.img
        src={isMoving ? dumpIcon : directionIcon}
        alt="direction"
        className="current-angle"
        style={{ transform: `translate(-50%, -50%) ${rotateStyle}` }}
      />
      <a.img
        src={minimapSrc}
        className="minimap-img"
        alt="map"
        style={mapStyle}
        onTransitionEnd={handlePanoMovingEnd}
        onLoad={handlePanoMovingEnd}
      />
    </a.div>
  )
}

export default Minimap
