import { FLOOR_PATH } from 'constants/path'

import f1MapImg from 'assets/images/overview/1fThumb.jpg'
import f2MapImg from 'assets/images/overview/2fThumb.jpg'
import f5MapImg from 'assets/images/overview/5fThumb.jpg'
import f6MapImg from 'assets/images/overview/6fThumb.jpg'

export const OVERVIEW_FLOOR_MAP = {
  [FLOOR_PATH.F6]: {
    text: '陸樓',
    mapSrc: f6MapImg,
    spots: ['空中花園', '堁夏咖啡'],
  },
  [FLOOR_PATH.F5]: {
    text: '伍樓',
    mapSrc: f5MapImg,
    spots: ['凸凸廳', '好樣食藝餐廳', 'I Mage3 非常圖像空間'],
  },
  [FLOOR_PATH.F2]: {
    text: '貳樓',
    mapSrc: f2MapImg,
    spots: ['大劇院', '中劇院', 'NTT紀念品店'],
  },
  [FLOOR_PATH.F1]: {
    text: '壹樓',
    mapSrc: f1MapImg,
    spots: ['服務台/售票處', '角落沙龍', '好樣度量衡咖啡', '好樣學潮', '陸府藝角', '藍米克'],
  },
}
